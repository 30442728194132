import React, { useState, useEffect, Component, useRef, useLayoutEffect } from 'react';
import { StyleSheet, Text, View , Dimensions, Animated, useWindowDimensions, Image, TouchableOpacity, Platform, FlatList, ScrollView, SafeAreaView, Pressable} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {PaperProvider, Drawer ,TextInput, Button, MD3LightTheme as DefaultTheme, IconButton, DataTable, List, ProgressBar, MD3Colors, Avatar, HelperText, Tooltip} from 'react-native-paper';
import { Icon, Divider, SearchBar, CheckBox, Slider} from 'react-native-elements';
import moment from 'moment';
import 'moment/locale/fr';
import Calendar3 from '../components/Calendar3';
import ModalI1 from '../components/ModalInfo';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';

const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Password = () => {

const [check1, setCheck1] = useState(false);
const [next, setNext] = useState(false);
const [DATA, setDATA] = useState([]);
const [itemP, setItemP] = useState([]);
const [secure, setsecure] = useState(true);
const [Password, setPassword] = useState('');
const [Csecure, setCsecure] = useState(true);
const [CPassword, setCPassword] = useState('');
const [Email, setEmail] = useState('');
const [Nom, setNom] = useState('');
const [step, setStep] = useState(1);
const [Code, setCode] = useState('');
const [expanded1, setExpanded1] = useState(true);
const [expanded2, setExpanded2] = useState(true);
const [modalVisible, setModalVisible] = useState(false);
var iChars = "~`!#$%^&*+=-[]\\';,./{}|\":<>?";

function $_GET(param) {
  var vars = {};
  window.location.href.replace( location.hash, '' ).replace( 
    /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    function( m, key, value ) { // callback
      vars[key] = value !== undefined ? value : '';
    }
  );

  if ( param ) {
    return vars[param] ? vars[param] : null;	
  }
  return vars;
}

var Mail = decodeURI( $_GET( 'Mail' ) )



useEffect(() => {
setEmail(Mail)
    
},[]);


const hasErrors = () => {
  let Maj = false;
    let car8 = false;
    let Scar =false;
    let Minu = false;
    let Chif = false;
    var long = Password.length ;

    if (long >= 8){car8 = true}else{car8 = false}

    for (var i = 0; i < long; i++)
    {if (iChars.indexOf(Password.charAt(i)) != -1 )
      {Scar = true
      break;}
      else {
      Scar = false
      }
    }
    
    for (var i = 0; i < long; i++)
    {if (Password.charAt(i) == Password.charAt(i).toUpperCase() )
      {  Maj = true
      break;}
      else {
         Maj = false
      }

    }

      for (var i = 0; i < long; i++)
    {if (Password.charAt(i) == Password.charAt(i).toLowerCase() )
      {Minu = true
      break;}
      else {
        Minu = false
       
      }
    }

      for (var i = 0; i < long; i++)
      { if (!isNaN(Password.charAt(i) * 1))
        {Chif = true
        break;}
        else {
        Chif = false
        }
      }
  
  if(car8 && Scar && Maj && Minu && Chif) 
      {return false
      }
    else {return true }
  

};

const ChasErrors = () => {
  if (Password == CPassword )
  {return false}
    else {return true }
};

const connect = () => {
  fetch('https://www.useradventure.net/saas_json_update_mdp_user.php', {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ Email:Email, pass:Password })
  })
    .then((response) => response.json())
    .then(() => { setModalVisible(true)
      })
      .catch((error) => console.log('error',error))

  }

  const go = () => {
    setModalVisible(false)
    document.location = 'https://saas.healthsquad.fr/';
      }
  
      return (
       
    
    <>
     
    <LinearGradient colors={['rgba(217, 228, 249, 1)','rgba(241, 246, 255, 1)']} start={[0.0, 1.4]} end={[1.4, 1.4]}  style={{flex : 1,flexDirection: 'row', borderTopLeftRadius:0,paddingHorizontal: 30, shadowColor: 'black', shadowOpacity: 0.4, shadowRadius: 7, shadowOffset: { height: 0, width:-7 }}}>
    
    <ScrollView>
    <View style={{  backgroundColor:"#FFFFFF", paddingHorizontal:15, borderRadius: 20,margin: 20, elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}>
    <View style={{ flexDirection:'row', backgroundColor:"#FFFFFF"}}>
    <View style={{flex:1}} >
    <Image style={{ width: '100%', resizeMode: 'contain',height :100 }} source={require("../assets/logo_HealthSquad_complet.png")} /> 
       </View>
    </View>
    </View>
   <View style={{ backgroundColor:"rgba(255, 255, 255, 0.4)", padding:20, margin:20, marginBottom:10, borderRadius: 20, elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}>
   <View style={{alignSelf:'center',width:350}} >
   <Text style={{fontSize:20, alignSelf:'center', marginTop: 20, fontWeight:'bold', textAlign:'center'}}>Modification du mot de passe de votre compte :</Text>
   <TextInput
 style={{height:60, marginTop:30 }}
 disabled={true}
 mode="outlined"
 outlineColor={'#3F78E0'}
 activeOutlineColor={'#3F78E0'}
 outlineStyle={{borderWidth:2, borderRadius:5}}
 label="Mail"
 value={Email}
 onChangeText={text => setEmail(text)}
 theme={{ colors: { onSurfaceVariant: '#3F78E0'} }}
/>
 
<TextInput
 style={{height:60 , marginTop:10}}
 mode="outlined"
 outlineColor={'#3F78E0'}
 activeOutlineColor={'#3F78E0'}
 outlineStyle={{borderWidth:2, borderRadius:5}}
 label="Nouveau mot de passe"
 secureTextEntry={secure}
 value={Password}
 onChangeText={Password => setPassword(Password)}
 theme={{ colors: { onSurfaceVariant: '#3F78E0'} }}
 right={<TextInput.Icon style={{marginTop:15}} icon={secure ? 'eye' : 'eye-off'} onPress={()=> setsecure(!secure)} />}
/>
<HelperText type="error" visible={hasErrors()}>
Nouveau mot de passe incorrect !
 </HelperText>
<TextInput
 style={{height:60 , marginTop:10}}
 mode="outlined"
 outlineColor={'#3F78E0'}
 activeOutlineColor={'#3F78E0'}
 outlineStyle={{borderWidth:2, borderRadius:5}}
 label="Confirmer mot de passe"
 secureTextEntry={Csecure}
 value={CPassword}
 onChangeText={CPassword => setCPassword(CPassword)}
 theme={{ colors: { onSurfaceVariant: '#3F78E0'} }}
 right={<TextInput.Icon style={{marginTop:15}} icon={secure ? 'eye' : 'eye-off'} onPress={()=> setCsecure(!Csecure)} />}
/>
<HelperText type="error" visible={ChasErrors()}>
Confirmation du nouveau mot de passe incorrect !
 </HelperText>




 <TouchableOpacity style={{ marginTop:  10, marginBottom:10}} disabled={!ChasErrors() && !hasErrors() ? false : true}  onPress={() => connect()}>
   <LinearGradient
       start={{ x: 0.5, y: 0.5 }}
        colors={!ChasErrors() && !hasErrors() ? ['#3F78E0', '#C24C99'] : ['grey', 'grey']}
        style={{ paddingVertical: 15, borderRadius: 50,marginHorizontal:0, alignItems:'center'}}
        >
      <Text style={{color:'white', fontSize: 20}}>Valider</Text> 
</LinearGradient>

</TouchableOpacity>
<Text style={{fontSize:14,  marginTop: 20, textAlign:'justify'}}>Attention, votre mot de passe doit comporter minimum 8 caractères, une minuscule, une majuscle, un chiffre et un caractère spécial.</Text>

</View> 




      </View> 
  </ScrollView>
  </LinearGradient>
    <ModalI1  Text1={'Votre mot de passe a bien été changé.'} Text2={'Retour au Saas'} isVisible ={modalVisible} onSubmit={()=>go()} onCancel={()=>setModalVisible(false)}/>
  </>

  )
}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    
  },
  modalView: {width: 450,
    margin: 30,
    backgroundColor: '#3F78E0',
    borderRadius: 5,
    padding: 0,
    elevation: 20 , 
    shadowColor: "#000000", 
    shadowOpacity: 0.5,
    shadowRadius: 5, 
    shadowOffset: { height: 5, width:5 }
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   TextInput: {
    height: 45,
    marginVertical:10,
    padding: 10,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 18
  },
  TextInput2: {
    height: 45,
    marginVertical:0,
    padding: 0,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 18
  },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
  fontSize:16,
 color:'white',
 fontWeight:'bold'
   },
   modalTextbold: {
    fontWeight:'bold'
    },
    modalVText: {
      paddingBottom:20,
     // alignItems: "center"
       }

  });

export default Password