import React, { useState, useEffect, Component, useRef } from 'react';
import {Text,Pressable,View,StyleSheet, Modal, Dimensions, useWindowDimensions, ScrollView, Image as Img, ImageBackground  } from 'react-native';
import { Icon, Divider, SearchBar, CheckBox, Slider} from 'react-native-elements';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { jsPDF } from "jspdf";
import '../styleSigne.css';
import * as WebBrowser from 'expo-web-browser';
import * as Device from 'expo-device';
import Logo from '../assets/logo_HealthSquad_complet.png'
import preview from '../assets/preview.jpg'
import imgcheck from '../assets/pngwing.png'
import moment from 'moment';
import 'moment/locale/fr';
import { useFonts } from 'expo-font';
import SignatureCanvas from 'react-signature-canvas'
import  '../components/BrittanySignature-normal'
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Signature = () => {
  const { height, width } = useWindowDimensions();
  const doc = new jsPDF({
    orientation: 'p',
    format: 'a4',
    compress:true
   });
  const [url, seturl] = useState('');
  const [docu, setdocu] = useState('');
  const [Nom, setnom] = useState('');
  const [resultat, setresultat] = useState('');
  const [Prenom, setprenom] = useState('');
  const [dateEntretien, setdateEntretien] = useState('');
  const [DateSignature, setDateSignature] = useState(new Date);
    const [client, setclient] = useState('');
    const [montant, setmontant] = useState('');
    const [maxWidth, setmaxWidth] = useState(400);
    const [nbCheck, setnbCheck] = useState(0);
    const [NumDocAutorite, setNumDocAutorite] = useState('');
    const [temps, settemps] = useState('');
    const [ArrayCheckbox, setArrayCheckbox] = useState([]);
    // const [Document1, setDocument1] = useState('');
    const [adresse, setadresse] = useState('');
    const [SigImg, setSigImg] = useState('');
    const [IDsign, setIDsign] = useState('');
    const [Signed, setSigned] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
    const [disabled, setdisabled] = useState(false);
    const [check1, setCheck1] = useState(false);
  const [fontsLoaded, fontError] = useFonts({
    'BrittanySignature': require('../assets/BrittanySignature.ttf'),
  });

  function $_GET(param) {
    var vars = {};
    window.location.href.replace( location.hash, '' ).replace( 
      /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
      function( m, key, value ) { // callback
        vars[key] = value !== undefined ? value : '';
      }
    );
  
    if ( param ) {
      return vars[param] ? vars[param] : null;	
    }
    return vars;
  }

var IDUser = decodeURI( $_GET( 'IDUser' ) )
var Secure = decodeURI( $_GET( 'Secure' ) )
var ID_Etude = decodeURI( $_GET( 'ID_Etude' ) )


  const Document1 = [
    {ordre: 1, Page: 1, type:'text', Top: 30, Left: 0 ,Align: 'center', Bold: true, Font: 'Times', FontSize: 24, MarginTop: 10, MarginLeft: 0, Value: `Convention – Accord de participation professionnels de santé pour un entretien avec l’utilisateur en présentiel.`   },
    {ordre: 2, Page: 1, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 20, MarginLeft: 0, Value: `Merci d’avoir accepté de participer à cette étude réalisée par Symplicity HealthSquad et UseConcept basés dans le Nord de la France, pour le compte d’un fabricant de dispositifs médicaux. Symplicity HealthSquad travaille dans le respect de la législation française et européenne relative à la protection des données personnelles (Loi du 6 janvier 1978 modifiée dite loi « Informatique et Libertés » et le règlement européen 2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection des Données »), et adhère également aux codes de déontologie régissant notre activité (EphMRA, ASOCS et ESOMAR) en matière d'anonymat, de confidentialité et d'éthique dans les études de marché. Notre Politique de confidentialité est consultable sur https://www.healthsquad.fr rubrique Politique de protection des données personnelles.`   },
    {ordre: 3, Page: 1, type:'checkbox', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 26, MarginTop: 20, MarginLeft: 5, Value: ``   },
    {ordre: 4, Page: 1, type:'text', Top: 350, Left: 20 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: -25, MarginLeft: 30, Value: `L’étude réalisée portera sur un entretien avec un professionnel de santé autour d’un produit de santé et aura lieu le ${moment(dateEntretien).format('Do MMMM YYYY')}. L'entretien sera réalisé en face à face ${adresse}. Il durera ${temps} minutes et à la fin de l'entretien, vous recevrez un dédommagement de ${montant} euros net. Cela en remerciement pour votre temps et votre participation. L'objectif de cette étude de marché est de recueillir votre opinion et personne n’essaiera de vous vendre quoi que ce soit. Toutes les informations fournies resteront confidentielles et seront rapportées uniquement de manière regroupée, sans aucune information permettant de vous identifier.`   },
    {ordre: 5, Page: 1, type:'text', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 20, MarginLeft: 0, Value: `Nous vous demandons de lire et d'accepter ce qui suit avant de participer à l'entretien.`},
    {ordre: 6, Page: 1, type:'checkbox', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 26, MarginTop: 20, MarginLeft: 5, Value: ``   },
    {ordre: 7, Page: 1, type:'text', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: -25, MarginLeft: 30, Value: `Je confirme vouloir participer à cet entretien d'étude de marché de mon plein gré et suis informé(e) que je peux y mettre un terme ou taire certaines informations si je le souhaite.`},
    {ordre: 6, Page: 1, type:'checkbox', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 26, MarginTop: 20, MarginLeft: 5, Value: ``   },
    {ordre: 8, Page: 1, type:'text', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: -25, MarginLeft: 30, Value: `Je comprends que toutes les données issues de cet entretien seront utilisées à des fins d’étude de marché uniquement.`},
    {ordre: 6, Page: 1, type:'checkbox', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 26, MarginTop: 20, MarginLeft: 5, Value: ``   },
    {ordre: 9, Page: 1, type:'text', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: -25, MarginLeft: 30, Value: `Je considèrerai toutes les informations qui me seront présentées durant cette étude comme étant confidentielles. Toutes les informations présentées pendant son déroulement ne le sont qu'à des fins d'évaluation des réactions qu’elles peuvent susciter. Il convient de les considérer comme hypothétiques. L'étude présentée ne doit pas être utilisée pour influencer des décisions en-dehors du cadre de cette étude.`},
    {ordre: 6, Page: 1, type:'checkbox', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 26, MarginTop: 20, MarginLeft: 5, Value: ``   },
    {ordre: 10, Page: 1, type:'text', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: -25, MarginLeft: 30, Value: `Je comprends que l'entretien auquel je participe fera l’objet d’un enregistrement audio et vidéo pour une analyse ultérieure. Cet enregistrement sera utilisé par UseConcept à des fins d'analyse.`},
    {ordre: 6, Page: 1, type:'checkbox', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 26, MarginTop: 20, MarginLeft: 5, Value: ``   },
    {ordre: 11, Page: 1, type:'text', Top: 30, Left: 105 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: -25, MarginLeft: 30, Value: `Si le fabricant commanditaire souhaite avoir accès à l'enregistrement de mon entretien, je signerai un consentement spécifique à cela.`},
    {ordre: 12, Page: 2, type:'text', Top: 30, Left: 0 ,Align: 'center', Bold: true, Font: 'Times', FontSize: 24, MarginTop: 10, MarginLeft: 0, Value: `LOI BERTRAND`   },
    {ordre: 13, Page: 2, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 20, MarginLeft: 0, Value: `Comme vous le savez, le Ministère des Solidarités et de la Santé demande davantage de transparence aux laboratoires pharmaceutiques concernant les indemnisations versées aux professionnels de santé. Cela signifie que les agences d’études de marché sont désormais dans l’obligation de fournir les détails tels que le nom et le montant des indemnisations versées aux professionnels de santé qui participent à des études de marché. En application du Code de santé publique et du décret d'application No. 2016-1939 du 28 Décembre 2016, publié au journal officiel le 30 décembre 2016, relatif à la transparence des bénéfices fournis par les sociétés produisant ou commercialisant des produits de santé ou cosmétiques destinés aux humains, certaines des informations relatives à cet accord seront publiées sur le site de transparence : www.transparence.sante.gouv.fr En application du décret d'application No. 2013-414 vous n'êtes pas en droit de vous opposer à la publication de telles données personnelles.`   },
    {ordre: 12, Page: 2, type:'text', Top: 30, Left: 0 ,Align: 'center', Bold: true, Font: 'Times', FontSize: 24, MarginTop: 20, MarginLeft: 0, Value: `LOI ANTI-CADEAUX`   },
    {ordre: 13, Page: 2, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 20, MarginLeft: 0, Value: `En application du décret n° 2020-730 du 15 juin 2020 relatif aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou des prestations de santé. Le décret précise les modalités relatives aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou des prestations de santé. Il détermine les personnes physiques ou morales concernées, la nature et les conditions des dérogations à l’interdiction d’offres d’avantages, ainsi que les modalités du régime de déclaration et d’autorisation des dérogations. Il procède en outre à la mise en cohérence des dispositions réglementaires du code de la santé publique rendue nécessaire par les modifications introduites par l’ordonnance du 19 janvier 2017 relative aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou prestations de santé.`   },
    {ordre: 13, Page: 2, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 20, MarginLeft: 0, Value: `L’Arrêté du 7 août 2020 fixant les montants en deçà desquels les avantages en nature ou en espèces sont considérés comme d'une valeur négligeable en application du 4° de l'article L. 1453-6 du code de la santé publique et à partir desquels une convention stipulant l’octroi des avantages est soumise à autorisation Convention transmise au conseil national de l’ordre concerné au plus tard huit jours ouvrable avant l’octroi de l’avantage au conseil national de l’ordre concerné.`   },
    {ordre: 13, Page: 2, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 20, MarginLeft: 0, Value: `Conformément à l’article L.4113-9, les médecins, les chirurgiens-dentistes en exercice, ainsi que les personnes qui demandent leur inscription au tableau de l'ordre des médecins ou des chirurgiens-dentistes, doivent communiquer au conseil départemental de l'ordre dont ils relèvent, les contrats et avenants ayant pour objet l'exercice de leur profession ainsi que, s'ils ne sont pas propriétaires de leur matériel et du local dans lequel ils exercent ou exerceront leur profession, les contrats ou avenants leur assurant l'usage de ce matériel et de ce local.
    Les mêmes obligations s'appliquent aux contrats et avenants ayant pour objet de transmettre sous condition résolutoire la propriété du matériel et du local.
    La communication prévue ci-dessus doit être faite dans le mois suivant la conclusion du contrat ou de l'avenant, afin de permettre l'application des articles L. 4121-2 et L. 4127-1.
    Tous les contrats et avenants dont la communication est exigée doivent être passés par écrit.
    Les contrats et avenants dont la communication est prévue ci-dessus doivent être tenus à la disposition du ministre chargé de la santé par le conseil départemental de l'ordre des médecins ou par le conseil départemental de l'ordre des chirurgiens-dentistes.
    Toute personne physique ou morale passant un contrat avec un médecin ou un chirurgien-dentiste doit le faire par écrit.`   },
    {ordre: 13, Page: 3, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 10, MarginLeft: 0, Value: `Article L1453-1 - I.-Les entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 à l'exception de ceux mentionnés aux 14°, 15° et 17° ou assurant des prestations
    associées à ces produits sont tenues de rendre publics, sur un site internet public unique, l'objet précis, la date, le bénéficiaire direct et le bénéficiaire final, et le montant des conventions qu'elles concluent avec :`   },
    {ordre: 13, Page: 3, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 5, MarginLeft: 0, Value: `1° Les professionnels de santé relevant de la quatrième partie du présent code ;
    2° Les associations de professionnels de santé ;
    3° Les étudiants se destinant aux professions relevant de la quatrième partie du présent code ainsi
    que les associations et groupements les représentant ;
    4° Les associations d'usagers du système de santé ;
    5° Les établissements de santé relevant de la sixième partie du présent code ;
    6° Les académies, les fondations, les sociétés savantes et les sociétés ou organismes de conseil
    intervenant dans le secteur des produits ou prestations mentionnés au premier alinéa ;
    7° Les personnes morales éditrices de presse, de services de radio ou de télévision et de services de
    communication au public en ligne ;
    7° bis Les personnes qui, dans les médias ou sur les réseaux sociaux, présentent un ou plusieurs
    produits de santé, de manière à influencer le public ;
    8° Les éditeurs de logiciels d'aide à la prescription et à la délivrance ;
    9° Les personnes morales assurant ou participant à la formation initiale ou continue ou au
    développement professionnel continu des professionnels de santé mentionnés au 1° du présent I.
    Les entreprises produisant ou commercialisant des produits mentionnés aux 14°, 15° et 17° du II de
    l'article L. 5311-1 ou assurant des prestations associées à ces produits sont tenues de rendre publique l'existence des conventions relatives à la conduite de travaux d'évaluation de la sécurité, de vigilance ou de recherche biomédicale qu'elles concluent avec les bénéficiaires mentionnés aux 1° à 9° du présent I.
    Cette obligation ne s'applique pas aux conventions régies par les articles L. 441-3 et L. 441-9 du code de commerce et qui ont pour objet l'achat de biens ou de services par les personnes physiques ou morales mentionnées aux 1° à 9° du présent I auprès des entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 du présent code ou assurant des prestations associées à ces produits.
    I bis.-Les entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 ou assurant des prestations associées à ces produits sont tenues de rendre publiques, au-delà d'un seuil fixé par décret, sur le site mentionné au I du présent article, les rémunérations versées à des personnes physiques ou morales dans le cadre des conventions mentionnées au même I.
    II.-La même obligation s'applique, au-delà d'un seuil fixé par décret, à tous les avantages en nature ou en espèces autres que les rémunérations mentionnées au I bis que les mêmes entreprises procurent, directement ou indirectement, aux personnes, associations, établissements, fondations, sociétés, organismes et organes mentionnés au I.
    II bis.-Les informations publiées sur le site internet public unique mentionné au I du présent article sont réutilisables, à titre gratuit, dans le respect de la finalité de transparence des liens d'intérêts et dans les conditions prévues à l'article L. 322-1 du code des relations entre le public et l'administration et, lorsque cette réutilisation donne lieu à un traitement de données, dans les conditions prévues par la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. 
    III.-Un décret en Conseil d'Etat, pris après avis de la Commission nationale de l'informatique et des libertés, fixe les conditions d'application du présent article, la nature des informations qui doivent être rendues publiques sur le site internet public unique, notamment l'objet précis et la date des conventions mentionnées au I, ainsi que les délais et modalités de publication et d'actualisation de ces informations.`   },
    {ordre: 13, Page: 4, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 5, MarginLeft: 0, Value: `Il précise également les modalités suivant lesquelles les ordres des professions de santé sont associés à cette publication.
    Article L.1453-3, Est interdit le fait, pour les personnes mentionnées à l'article L. 1453-4, de recevoir des avantages en espèces ou en nature, sous quelque forme que ce soit, d'une façon directe ou indirecte, proposés ou procurés par les personnes mentionnées à l'article L. 1453-5.
    Selon l’article L.1453-4, l'interdiction prévue à l'article L. 1453-3 est applicable :
    1° Aux personnes exerçant une profession de santé réglementée par le présent code, aux ostéopathes et aux chiropracteurs mentionnés à l'article 75 de la loi n° 2002-303 du 4 mars 2002 relative aux droits des malades et à la qualité du système de santé et aux psychothérapeutes mentionnés à l'article 52 de la loi n° 2004-806 du 9 août 2004 relative à la politique de santé publique ;
    2° Aux étudiants en formation initiale se destinant à l'exercice de l'une des professions mentionnées au 1° et aux personnes en formation continue ou suivant une action de développement professionnel continu dans ce champ ;
    3° Aux associations qui regroupent des personnes mentionnées aux 1° et 2°, dont celles intervenant dans le champ de la formation de ces personnes, et notamment aux sociétés savantes et aux conseils nationaux professionnels mentionnés à l'article L. 4021-3 ;
    4° Aux fonctionnaires et agents des administrations de l'Etat, des collectivités territoriales et de leurs établissements publics ou de toute autre autorité administrative qui élaborent ou participent à l'élaboration d'une politique publique en matière de santé ou de sécurité sociale ou sont titulaires de pouvoirs de police administrative à caractère sanitaire.`   },
    {ordre: 12, Page: 4, type:'text', Top: 30, Left: 0 ,Align: 'center', Bold: true, Font: 'Times', FontSize: 24, MarginTop: 20, MarginLeft: 0, Value: `CONSERVATION DES DONNEES ET DROITS`   },
    {ordre: 13, Page: 4, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 10, MarginLeft: 0, Value: `Les données personnelles que nous recueillons seront traitées de manière confidentielle et uniquement utilisées à des fins d'étude de marché. Elles pourront être conservées après la fin de l'étude pendant une durée 3 ans si vous n'avez répondu activement à aucune sollicitation par Symplicity HealthSquad. Elles pourront être conservées pendant 2 ans par UseConcept. Vous pouvez demander à tout moment à avoir accès à ces données personnelles et à les faire modifier ou détruire. Vous pouvez également en demander la portabilité et la limitation du traitement. Veuillez nous contacter à cette adresse maxime.aldegheri@healthsquad.fr dans ce cas. Vous avez le droit d'introduire une réclamation auprès d’une autorité de contrôle si votre demande n’est pas prise en charge dans le mois qui suit. (CNIL : https://www.cnil.fr). Notre Politique de confidentialité est consultable sur : https://www.healthsquad.fr rubrique Politique de protection des données personnelles`   },
    {ordre: 13, Page: 4, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 10, MarginLeft: 0, Value: `J’accepte de participer à l’entretien dans les conditions indiquées ci-dessus et d’être recontacter si certains points doivent être reclarifiés.`   },
    {ordre: 13, Page: 4, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 10, MarginLeft: 0, Value: `Nom : ${Nom.charAt(0).toUpperCase() + Nom.slice(1) + " " + Prenom.charAt(0).toUpperCase() + Prenom.slice(1)}`  },
    {ordre: 13, Page: 4, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 10, MarginLeft: 0, Value: `Date : ${moment(DateSignature).format('Do-MM-YYYY')}`   },
    {ordre: 13, Page: 4, type:'text', Top: 105, Left: 10 ,Align: 'left', Bold: false, Font: 'Times', FontSize: 18, MarginTop: 10, MarginLeft: 0, Value: `Signature :`   },
    {ordre: 12, Page: 4, type:'text', Top: 30, Left: 0 ,Align: 'left', Bold: true, Font: 'BrittanySignature', FontSize: 38, MarginTop: 20, MarginLeft: 120, image: `${preview}`, Value: `${Nom.charAt(0).toUpperCase() + Nom.slice(1) + " " + Prenom.charAt(0).toUpperCase() + Prenom.slice(1)}`   },
  ]

  const MAJ = () => {

    fetch('https://www.useradventure.net/siteHS_json_get_sign.php', {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ IDUser: IDUser,  Secure: Secure, ID_Etude: ID_Etude})
    })
      .then((response) => response.json())
      .then((response) => { console.log('response', response)
      setresultat(response.length)
        setdocu(response[0].Doc)
        setnom(response[0].Nom)
        setprenom(response[0].Prenom)
        setIDsign(response[0].ID)
        setSigned(response[0].Signed)
        setmontant(response[0].Variable[0][0])
        settemps(response[0].Variable[0][1])
        setdateEntretien(response[0].Variable[0][2])
        setadresse(response[0].Variable[0][3])
        //setmontant(response[0].JSON.parse(Variable[0]))
        //setSigImg(response[0].ImgSigne)
        })
        .catch((error) => console.log('error',error))
  }

  useEffect(() => {
    const newt =[]
    for (var i = 0; i < Document1.length; i++) {
      if(Document1[i].type == 'checkbox'){
        newt.push({id:i, checked: false})
       } 
       setArrayCheckbox(newt) 
       console.log('ArrayCheckbox',newt)
      }

      MAJ()

    // fetch('https://www.useradventure.net/siteHS_json_get_doc.php', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json',  // It can be used to overcome cors errors
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ ID: 1 })
    // })
    //   .then((response) => response.json())
    //   .then((response) => { console.log('response', response[0].Document)
    //     //setDocument1(response[0].Document)
    //     })
    //     .catch((error) => console.log('error',error))
    //setdocu(doc)
   // createPDF1()
 
    }, [fontsLoaded, fontError]);

    function createPDF2(sig) {
 
 
      var img = new Image()
       img.src = Logo
       doc.addImage(img, 'png', 155, 5, 45, 13)
      doc.addPage();
      doc.addPage();
      doc.addPage();
      
      
       Document1.map((item,index) => (
        // console.log('nbligne',Document1.filter(item => item.ordre <= index).reduce(function (a, b) {
        //   return a.FontSize + b.FontSize},0)),
       console.log('nbligne',item.ordre , Document1.filter(item => item.ordre <= index).reduce((a,c) =>  a + Math.ceil(c.Value.length/100)  , 0 )),
       console.log('nbligne',item.ordre , Document1.filter(item => item.ordre <= index + 1).reduce((a,c) =>  a + Math.ceil(c.MarginTop)  , 0 )),
        doc.setPage(item.Page),
        doc.setFont(item.Font),
        doc.setFontSize(item.FontSize - 4),
        item.type == "text" ?
        doc.text(item.Value, item.Left , item.Top, {align: item.Align ,maxWidth: maxWidth})
        :
        doc.addImage(img, 'png', item.Align == "center" ? 105 : item.MarginLeft + 10, Document1.filter(item => item.ordre <= index).reduce((a,c) =>  a + Math.ceil(c.Value.length/100) , 0 ) * 8 + Document1.filter(item => item.ordre <= index + 1).reduce((a,c) =>  a + Math.ceil(c.MarginTop)  , 0 ) + 20, 5, 15)

      ))
      const blob = doc.output('blob');
      //setblob(blob)  
      doc.save("a5.pdf");
      seturl(URL.createObjectURL(blob));
      }
    
    function createPDF3(sig) {
 
 
      var img = new Image()
       img.src = Logo
       doc.addImage(img, 'png', 155, 5, 45, 13)
      doc.addPage();
      doc.addPage();
      doc.addPage();
      
      
       Document1.map((item,index) => (
        // console.log('nbligne',Document1.filter(item => item.ordre <= index).reduce(function (a, b) {
        //   return a.FontSize + b.FontSize},0)),
       console.log('nbligne',item.ordre , Document1.filter(item => item.ordre <= index).reduce((a,c) =>  a + Math.ceil(c.Value.length/100)  , 0 )),
       console.log('nbligne',item.ordre , Document1.filter(item => item.ordre <= index + 1).reduce((a,c) =>  a + Math.ceil(c.MarginTop)  , 0 )),
        doc.setPage(item.Page),
        doc.setFont(item.Font),
        doc.setFontSize(item.FontSize - 4),
        item.type == "text" ?
        doc.text(item.Value, item.Align == "center" ? 105 : item.MarginLeft /2 + 10, Document1.filter(item => item.ordre <= index).reduce((a,c) =>  a + Math.ceil(c.Value.length/100) , 0 ) * 8 + Document1.filter(item => item.ordre <= index + 1).reduce((a,c) =>  a + Math.ceil(c.MarginTop)  , 0 ) + 20, {align: item.Align ,maxWidth: maxWidth - item.MarginLeft /2})
        :
        doc.addImage(img, 'png', item.Align == "center" ? 105 : item.MarginLeft + 10, Document1.filter(item => item.ordre <= index).reduce((a,c) =>  a + Math.ceil(c.Value.length/100) , 0 ) * 8 + Document1.filter(item => item.ordre <= index + 1).reduce((a,c) =>  a + Math.ceil(c.MarginTop)  , 0 ) + 20, 5, 15)

      ))
    //   doc.setFont('Times')  Math.ceil(Document1[index].Value.length/100) * (item.FontSize - 4)
    //   doc.setPage(1);
    //    doc.addImage(img, 'png', 155, 5, 45, 13)
    //    doc.setFontSize(18);
    //    doc.text(`Convention – Accord de participation professionnels de santé pour un entretien avec l’utilisateur en présentiel.`, 105, 30, {align:'center',maxWidth: 190});
    //    doc.setFontSize(13);
    //    doc.text(`Merci d’avoir accepté de participer à cette étude réalisée par UserAdventure et UseConcept basés dans le Nord de la France, pour le compte d’un fabricant de dispositifs médicaux. UserAdventure travaille dans le respect de la législation française et européenne relative à la protection des données personnelles (Loi du 6 janvier 1978 modifiée dite loi « Informatique et Libertés » et le règlement européen 2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection des Données »), et adhère également aux codes de déontologie régissant notre activité (EphMRA, ASOCS et ESOMAR) en matière d'anonymat, de confidentialité et d'éthique dans les études de marché. Notre Politique de confidentialité est consultable sur https://www.useradventure.fr rubrique Politique de protection des données personnelles.`, 10, 50, {maxWidth: 190});
    //    doc.setTextColor('#FFFFFF')
    //    doc.text(`${check1}`, 20, 103, {maxWidth: 180});
    //    doc.setTextColor('#000000')
    //    doc.text(`L’étude réalisée portera sur un entretien avec un professionnel de santé autour d’un produit de santé et aura lieu le ${moment(dateEntretien).format('Do MMMM YYYY')}. L'entretien sera réalisé en face à face ${adresse}. Il durera ${temps} minutes et à la fin de l'entretien, vous recevrez un dédommagement de ${montant} euros net. Cela en remerciement pour votre temps et votre participation. L'objectif de cette étude de marché est de recueillir votre opinion et personne n’essaiera de vous vendre quoi que ce soit. Toutes les informations fournies resteront confidentielles et seront rapportées uniquement de manière regroupée, sans aucune information permettant de vous identifier.`, 20, 105, {maxWidth: 180});
    //    doc.text(`Nous vous demandons de lire et d'accepter ce qui suit avant de participer à l'entretien.`, 10, 145, {maxWidth: 190});
    //    doc.setTextColor('#FFFFFF')
    //    doc.text(`${check2}`, 20, 153, {maxWidth: 180});
    //    doc.setTextColor('#000000')
    //    doc.text(`Je confirme vouloir participer à cet entretien d'étude de marché de mon plein gré et suis informé(e) que je peux y mettre un terme ou taire certaines informations si je le souhaite.`, 20, 155, {maxWidth: 180});
    //    doc.setTextColor('#FFFFFF')
    //    doc.text(`${check3}`, 20, 168, {maxWidth: 180});
    //    doc.setTextColor('#000000')
    //    doc.text(`Je comprends que toutes les données issues de cet entretien seront utilisées à des fins d’étude de marché uniquement.`, 20, 170, {maxWidth: 180});
    //    doc.setTextColor('#FFFFFF')
    //    doc.text(`${check4}`, 20, 183, {maxWidth: 180});
    //    doc.setTextColor('#000000')
    //    doc.text(`Je considèrerai toutes les informations qui me seront présentées durant cette étude comme étant confidentielles. Toutes les informations présentées pendant son déroulement ne le sont qu'à des fins d'évaluation des réactions qu’elles peuvent susciter. Il convient de les considérer comme hypothétiques. L'étude présentée ne doit pas être utilisée pour influencer des décisions en-dehors du cadre de cette étude.`, 20, 185, {maxWidth: 180});
    //    doc.setTextColor('#FFFFFF')
    //    doc.text(`${check5}`, 20, 213, {maxWidth: 180});
    //    doc.setTextColor('#000000')
    //    doc.text(`Je comprends que l'entretien auquel je participe fera l’objet d’un enregistrement audio et vidéo pour une analyse ultérieure. Cet enregistrement sera utilisé par UseConcept à des fins d'analyse.`, 20, 215, {maxWidth: 180});
    //    doc.setTextColor('#FFFFFF')
    //    doc.text(`${check6}`, 20, 228, {maxWidth: 180});
    //    doc.setTextColor('#000000')
    //    doc.text(`Si le fabricant commanditaire souhaite avoir accès à l'enregistrement de mon entretien, je signerai un consentement spécifique à cela.`, 20, 230, {maxWidth: 180});
    //    doc.text(`1/4`, 105, 285, {align:'center',maxWidth: 190});
    
    //    doc.setPage(2);
    //    doc.setTextColor('#000000')
    //    doc.addImage(img, 'png', 155, 5, 45, 13)
    //    doc.setFontSize(18);
    //    doc.text(`LOI BERTRAND`, 105, 30, {align:'center',maxWidth: 190});
    //    doc.setFontSize(13);
    //    doc.text(`Comme vous le savez, le Ministère des Solidarités et de la Santé demande davantage de transparence aux laboratoires pharmaceutiques concernant les indemnisations versées aux professionnels de santé. Cela signifie que les agences d’études de marché sont désormais dans l’obligation de fournir les détails tels que le nom et le montant des indemnisations versées aux professionnels de santé qui participent à des études de marché. En application du Code de santé publique et du décret d'application No. 2016-1939 du 28 Décembre 2016, publié au journal officiel le 30 décembre 2016, relatif à la transparence des bénéfices fournis par les sociétés produisant ou commercialisant des produits de santé ou cosmétiques destinés aux humains, certaines des informations relatives à cet accord seront publiées sur le site de transparence : www.transparence.sante.gouv.fr En application du décret d'application No. 2013-414 vous n'êtes pas en droit de vous opposer à la publication de telles données personnelles.`, 10, 40, {maxWidth: 190});
    //    doc.setFontSize(18);
    //    doc.text(`LOI ANTI-CADEAUX`, 105, 100, {align:'center',maxWidth: 190});
    //    doc.setFontSize(13);
    //    doc.text(`En application du décret n° 2020-730 du 15 juin 2020 relatif aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou des prestations de santé. Le décret précise les modalités relatives aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou des prestations de santé. Il détermine les personnes physiques ou morales concernées, la nature et les conditions des dérogations à l’interdiction d’offres d’avantages, ainsi que les modalités du régime de déclaration et d’autorisation des dérogations. Il procède en outre à la mise en cohérence des dispositions réglementaires du code de la santé publique rendue nécessaire par les modifications introduites par l’ordonnance du 19 janvier 2017 relative aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou prestations de santé.`, 10, 110, {maxWidth: 190});
    //    doc.text(`L’Arrêté du 7 août 2020 fixant les montants en deçà desquels les avantages en nature ou en espèces sont considérés comme d'une valeur négligeable en application du 4° de l'article L. 1453-6 du code de la santé publique et à partir desquels une convention stipulant l’octroi des avantages est soumise à autorisation Convention transmise au conseil national de l’ordre concerné au plus tard huit jours ouvrable avant l’octroi de l’avantage au conseil national de l’ordre concerné.`, 10, 165, {maxWidth: 190});
    //    doc.text(`Conformément à l’article L.4113-9, les médecins, les chirurgiens-dentistes en exercice, ainsi que les personnes qui demandent leur inscription au tableau de l'ordre des médecins ou des chirurgiens-dentistes, doivent communiquer au conseil départemental de l'ordre dont ils relèvent, les contrats et avenants ayant pour objet l'exercice de leur profession ainsi que, s'ils ne sont pas propriétaires de leur matériel et du local dans lequel ils exercent ou exerceront leur profession, les contrats ou avenants leur assurant l'usage de ce matériel et de ce local.
    //    Les mêmes obligations s'appliquent aux contrats et avenants ayant pour objet de transmettre sous condition résolutoire la propriété du matériel et du local.
    //    La communication prévue ci-dessus doit être faite dans le mois suivant la conclusion du contrat ou de l'avenant, afin de permettre l'application des articles L. 4121-2 et L. 4127-1.
    //    Tous les contrats et avenants dont la communication est exigée doivent être passés par écrit.
    //    Les contrats et avenants dont la communication est prévue ci-dessus doivent être tenus à la disposition du ministre chargé de la santé par le conseil départemental de l'ordre des médecins ou par le conseil départemental de l'ordre des chirurgiens-dentistes.
    //    Toute personne physique ou morale passant un contrat avec un médecin ou un chirurgien-dentiste doit le faire par écrit.`, 10, 200, {maxWidth: 190});
    //    doc.text(`2/4`, 105, 285, {align:'center',maxWidth: 190});
    //    doc.setPage(3);
    //    doc.setTextColor('#000000')
    //    doc.setFontSize(13);
    //    doc.addImage(img, 'png', 155, 5, 45, 13)
    // doc.text(`Article L1453-1 - I.-Les entreprises produisant ou commercialisant des produits mentionnés au II de
    // l'article L. 5311-1 à l'exception de ceux mentionnés aux 14°, 15° et 17° ou assurant des prestations associées à ces produits sont tenues de rendre publics, sur un site internet public unique, l'objet précis, la date, le bénéficiaire direct et le bénéficiaire final, et le montant des conventions qu'elles concluent avec :`, 10, 30, {maxWidth: 190});
    // doc.text(`1° Les professionnels de santé relevant de la quatrième partie du présent code ;
    // 2° Les associations de professionnels de santé ;
    // 3° Les étudiants se destinant aux professions relevant de la quatrième partie du présent code ainsi que les associations et groupements les représentant ;
    // 4° Les associations d'usagers du système de santé ;
    // 5° Les établissements de santé relevant de la sixième partie du présent code ;
    // 6° Les académies, les fondations, les sociétés savantes et les sociétés ou organismes de conseil intervenant dans le secteur des produits ou prestations mentionnés au premier alinéa ;
    // 7° Les personnes morales éditrices de presse, de services de radio ou de télévision et de services de communication au public en ligne ;
    // 7° bis Les personnes qui, dans les médias ou sur les réseaux sociaux, présentent un ou plusieurs produits de santé, de manière à influencer le public ;
    // 8° Les éditeurs de logiciels d'aide à la prescription et à la délivrance ;
    // 9° Les personnes morales assurant ou participant à la formation initiale ou continue ou au développement professionnel continu des professionnels de santé mentionnés au 1° du présent I.
    // Les entreprises produisant ou commercialisant des produits mentionnés aux 14°, 15° et 17° du II de l'article L. 5311-1 ou assurant des prestations associées à ces produits sont tenues de rendre publique l'existence des conventions relatives à la conduite de travaux d'évaluation de la sécurité, de vigilance ou de recherche biomédicale qu'elles concluent avec les bénéficiaires mentionnés aux 1° à 9° du présent I.
    // Cette obligation ne s'applique pas aux conventions régies par les articles L. 441-3 et L. 441-9 du code de commerce et qui ont pour objet l'achat de biens ou de services par les personnes physiques ou morales mentionnées aux 1° à 9° du présent I auprès des entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 du présent code ou assurant des prestations associées à ces produits.
    // I bis.-Les entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 ou assurant des prestations associées à ces produits sont tenues de rendre publiques, au-delà d'un seuil fixé par décret, sur le site mentionné au I du présent article, les rémunérations versées à des personnes physiques ou morales dans le cadre des conventions mentionnées au même I.
    // II.-La même obligation s'applique, au-delà d'un seuil fixé par décret, à tous les avantages en nature ou en espèces autres que les rémunérations mentionnées au I bis que les mêmes entreprises procurent, directement ou indirectement, aux personnes, associations, établissements, fondations, sociétés, organismes et organes mentionnés au I.
    // II bis.-Les informations publiées sur le site internet public unique mentionné au I du présent article sont réutilisables, à titre gratuit, dans le respect de la finalité de transparence des liens d'intérêts et dans les conditions prévues à l'article L. 322-1 du code des relations entre le public et l'administration et, lorsque cette réutilisation donne lieu à un traitement de données, dans les conditions prévues par la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. `, 10, 60, {maxWidth: 190});
    // doc.text(`3/4`, 105, 285, {align:'center',maxWidth: 190});
    // doc.setPage(4);
    // doc.setTextColor('#000000')
    // doc.setFontSize(13);
    // doc.addImage(img, 'png', 155, 5, 45, 13)
    
    // doc.text(`III.-Un décret en Conseil d'Etat, pris après avis de la Commission nationale de l'informatique et des libertés, fixe les conditions d'application du présent article, la nature des informations qui doivent être rendues publiques sur le site internet public unique, notamment l'objet précis et la date des conventions mentionnées au I, ainsi que les délais et modalités de publication et d'actualisation de ces informations. Il précise également les modalités suivant lesquelles les ordres des professions de santé sont associés à cette publication.
    // Article L.1453-3, Est interdit le fait, pour les personnes mentionnées à l'article L. 1453-4, de recevoir des avantages en espèces ou en nature, sous quelque forme que ce soit, d'une façon directe ou indirecte, proposés ou procurés par les personnes mentionnées à l'article L. 1453-5.
    // Selon l’article L.1453-4, l'interdiction prévue à l'article L. 1453-3 est applicable :
    // 1° Aux personnes exerçant une profession de santé réglementée par le présent code, aux ostéopathes et aux chiropracteurs mentionnés à l'article 75 de la loi n° 2002-303 du 4 mars 2002 relative aux droits des malades et à la qualité du système de santé et aux psychothérapeutes mentionnés à l'article 52 de la loi n° 2004-806 du 9 août 2004 relative à la politique de santé publique ;
    // 2° Aux étudiants en formation initiale se destinant à l'exercice de l'une des professions mentionnées au 1° et aux personnes en formation continue ou suivant une action de développement professionnel continu dans ce champ ;
    // 3° Aux associations qui regroupent des personnes mentionnées aux 1° et 2°, dont celles intervenant dans le champ de la formation de ces personnes, et notamment aux sociétés savantes et aux conseils nationaux professionnels mentionnés à l'article L. 4021-3 ;
    // 4° Aux fonctionnaires et agents des administrations de l'Etat, des collectivités territoriales et de leurs établissements publics ou de toute autre autorité administrative qui élaborent ou participent à l'élaboration d'une politique publique en matière de santé ou de sécurité sociale ou sont titulaires de pouvoirs de police administrative à caractère sanitaire.  `, 10, 30, {maxWidth: 190});
    // doc.setFontSize(18);
    // doc.text(`CONSERVATION DES DONNEES ET DROITS`, 105, 165, {align:'center',maxWidth: 190});
    // doc.setFontSize(13);
    // doc.text(`Les données personnelles que nous recueillons seront traitées de manière confidentielle et uniquement utilisées à des fins d'étude de marché. Elles pourront être conservées après la fin de l'étude pendant une durée 3 ans si vous n'avez répondu activement à aucune sollicitation par UserAdventure. Elles pourront être conservées pendant 2 ans par UseConcept. Vous pouvez demander à tout moment à avoir accès à ces données personnelles et à les faire modifier ou détruire. Vous pouvez également en demander la portabilité et la limitation du traitement. Veuillez nous contacter à cette adresse maxime.aldegheri@useradventure.fr dans ce cas. Vous avez le droit d'introduire une réclamation auprès d’une autorité de contrôle si votre demande n’est pas prise en charge dans le mois qui suit. (CNIL : https://www.cnil.fr). Notre Politique de confidentialité est consultable sur : https://www.useradventure.fr rubrique Politique de protection des données personnelles`, 10, 175, {maxWidth: 190});
    // doc.text(`J’accepte de participer à l’entretien dans les conditions indiquées ci-dessus et d’être recontacter si certains points doivent être reclarifiés.`, 10, 235, {maxWidth: 190});
    // doc.text(`Nom :`, 10, 250, {maxWidth: 190});
    // doc.text(`Aldegheri Maxime`, 30, 250, {maxWidth: 190});
    
    // doc.text(`Date : `, 10, 260, {maxWidth: 190});
    // doc.text(`${DateSign}`, 30, 260, {maxWidth: 190});
    // doc.text(`Signature : `, 10, 270, {maxWidth: 190});
    
    // if(sig){
    //   doc.addImage(sig, 'png', 20, 260, 100, 35)
    // }else{
    // doc.setFont('BrittanySignature-normal');
    // doc.setFontSize(28);
    // doc.text(`Aldegheri Maxime`, 35, 273, {maxWidth: 190});
    
    // }
    // // var checkBox = new jsPDF.API.AcroFormCheckBox();
    // // checkBox.fieldName = "CheckBox1";
    // // checkBox.Rect = [50, 10, 5, 5];
    // // doc.addField(checkBox);
    // // var checkBox = new jsPDF.API.AcroFormCheckBox();
    // //     checkBox.fieldName = "CheckBox2";
    // //     checkBox.x = 150;
    // //     checkBox.y = 270;
    // //     checkBox.width = 30;
    // //     checkBox.height = 30;
    // //     checkBox.fontSize = 20;
    // //     checkBox.value = 'Off'; // or On
    // //     checkBox.appearanceState = 'Off'; // or On
    // //     checkBox.color = 'red';
    // //     doc.addField(checkBox);
    // //  doc.setFont('Times')
    // doc.setFontSize(13); 
    // doc.setFont('Times')
    // doc.text(`4/4`, 105, 285, {align:'center',maxWidth: 190});
    const blob = doc.output('blob');
    //setblob(blob)  
    doc.save("a5.pdf");
    seturl(URL.createObjectURL(blob));
    }


    function createPDF1(sig) {
 
 
      var img = new Image()
       img.src = Logo
      doc.addPage();
      doc.addPage();
      doc.addPage();
      doc.setFont('Times')
      doc.setPage(1);
       doc.addImage(img, 'png', 155, 5, 45, 13)
       doc.setFontSize(18);
       doc.text(`Convention – Accord de participation professionnels de santé pour un entretien avec l’utilisateur en présentiel.`, 105, 30, {align:'center',maxWidth: 190});
       doc.setFontSize(13);
       doc.text(`Merci d’avoir accepté de participer à cette étude réalisée par UserAdventure et UseConcept basés dans le Nord de la France, pour le compte d’un fabricant de dispositifs médicaux. UserAdventure travaille dans le respect de la législation française et européenne relative à la protection des données personnelles (Loi du 6 janvier 1978 modifiée dite loi « Informatique et Libertés » et le règlement européen 2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection des Données »), et adhère également aux codes de déontologie régissant notre activité (EphMRA, ASOCS et ESOMAR) en matière d'anonymat, de confidentialité et d'éthique dans les études de marché. Notre Politique de confidentialité est consultable sur https://www.useradventure.fr rubrique Politique de protection des données personnelles.`, 10, 50, {maxWidth: 190});
       doc.setTextColor('#FFFFFF')
       doc.addImage(imgcheck, 'png', 15, 94, 6, 6);
       doc.setTextColor('#000000')
       doc.text(`L’étude réalisée portera sur un entretien avec un professionnel de santé autour d’un produit de santé et aura lieu le ${moment(dateEntretien).format('Do MMMM YYYY')}. L'entretien sera réalisé en face à face ${adresse}. Il durera ${temps} minutes et à la fin de l'entretien, vous recevrez un dédommagement de ${montant} euros net. Cela en remerciement pour votre temps et votre participation. L'objectif de cette étude de marché est de recueillir votre opinion et personne n’essaiera de vous vendre quoi que ce soit. Toutes les informations fournies resteront confidentielles et seront rapportées uniquement de manière regroupée, sans aucune information permettant de vous identifier.`, 20, 100, {maxWidth: 180});
       doc.text(`Nous vous demandons de lire et d'accepter ce qui suit avant de participer à l'entretien.`, 10, 145, {maxWidth: 190});
       doc.setTextColor('#FFFFFF')
       doc.addImage(imgcheck, 'png', 15, 149, 6, 6);
       doc.setTextColor('#000000')
       doc.text(`Je confirme vouloir participer à cet entretien d'étude de marché de mon plein gré et suis informé(e) que je peux y mettre un terme ou taire certaines informations si je le souhaite.`, 20, 155, {maxWidth: 180});
       doc.setTextColor('#FFFFFF')
       doc.addImage(imgcheck, 'png', 15, 164, 6, 6);
       doc.setTextColor('#000000')
       doc.text(`Je comprends que toutes les données issues de cet entretien seront utilisées à des fins d’étude de marché uniquement.`, 20, 170, {maxWidth: 180});
       doc.setTextColor('#FFFFFF')
       doc.addImage(imgcheck, 'png', 15, 179, 6, 6);
       doc.setTextColor('#000000')
       doc.text(`Je considèrerai toutes les informations qui me seront présentées durant cette étude comme étant confidentielles. Toutes les informations présentées pendant son déroulement ne le sont qu'à des fins d'évaluation des réactions qu’elles peuvent susciter. Il convient de les considérer comme hypothétiques. L'étude présentée ne doit pas être utilisée pour influencer des décisions en-dehors du cadre de cette étude.`, 20, 185, {maxWidth: 180});
       doc.setTextColor('#FFFFFF')
       doc.addImage(imgcheck, 'png', 15, 209, 6, 6);
       doc.setTextColor('#000000')
       doc.text(`Je comprends que l'entretien auquel je participe fera l’objet d’un enregistrement audio et vidéo pour une analyse ultérieure. Cet enregistrement sera utilisé par UseConcept à des fins d'analyse.`, 20, 215, {maxWidth: 180});
       doc.setTextColor('#FFFFFF')
       doc.addImage(imgcheck, 'png', 15, 224, 6, 6);
       doc.setTextColor('#000000')
       doc.text(`Si le fabricant commanditaire souhaite avoir accès à l'enregistrement de mon entretien, je signerai un consentement spécifique à cela.`, 20, 230, {maxWidth: 180});
       doc.text(`1/4`, 105, 285, {align:'center',maxWidth: 190});
    
       doc.setPage(2);
       doc.setTextColor('#000000')
       doc.addImage(img, 'png', 155, 5, 45, 13)
       doc.setFontSize(18);
       doc.text(`LOI BERTRAND`, 105, 30, {align:'center',maxWidth: 190});
       doc.setFontSize(13);
       doc.text(`Comme vous le savez, le Ministère des Solidarités et de la Santé demande davantage de transparence aux laboratoires pharmaceutiques concernant les indemnisations versées aux professionnels de santé. Cela signifie que les agences d’études de marché sont désormais dans l’obligation de fournir les détails tels que le nom et le montant des indemnisations versées aux professionnels de santé qui participent à des études de marché. En application du Code de santé publique et du décret d'application No. 2016-1939 du 28 Décembre 2016, publié au journal officiel le 30 décembre 2016, relatif à la transparence des bénéfices fournis par les sociétés produisant ou commercialisant des produits de santé ou cosmétiques destinés aux humains, certaines des informations relatives à cet accord seront publiées sur le site de transparence : www.transparence.sante.gouv.fr En application du décret d'application No. 2013-414 vous n'êtes pas en droit de vous opposer à la publication de telles données personnelles.`, 10, 40, {maxWidth: 190});
       doc.setFontSize(18);
       doc.text(`LOI ANTI-CADEAUX`, 105, 100, {align:'center',maxWidth: 190});
       doc.setFontSize(13);
       doc.text(`En application du décret n° 2020-730 du 15 juin 2020 relatif aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou des prestations de santé. Le décret précise les modalités relatives aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou des prestations de santé. Il détermine les personnes physiques ou morales concernées, la nature et les conditions des dérogations à l’interdiction d’offres d’avantages, ainsi que les modalités du régime de déclaration et d’autorisation des dérogations. Il procède en outre à la mise en cohérence des dispositions réglementaires du code de la santé publique rendue nécessaire par les modifications introduites par l’ordonnance du 19 janvier 2017 relative aux avantages offerts par les personnes fabriquant ou commercialisant des produits ou prestations de santé.`, 10, 110, {maxWidth: 190});
       doc.text(`L’Arrêté du 7 août 2020 fixant les montants en deçà desquels les avantages en nature ou en espèces sont considérés comme d'une valeur négligeable en application du 4° de l'article L. 1453-6 du code de la santé publique et à partir desquels une convention stipulant l’octroi des avantages est soumise à autorisation Convention transmise au conseil national de l’ordre concerné au plus tard huit jours ouvrable avant l’octroi de l’avantage au conseil national de l’ordre concerné.`, 10, 165, {maxWidth: 190});
       doc.text(`Conformément à l’article L.4113-9, les médecins, les chirurgiens-dentistes en exercice, ainsi que les personnes qui demandent leur inscription au tableau de l'ordre des médecins ou des chirurgiens-dentistes, doivent communiquer au conseil départemental de l'ordre dont ils relèvent, les contrats et avenants ayant pour objet l'exercice de leur profession ainsi que, s'ils ne sont pas propriétaires de leur matériel et du local dans lequel ils exercent ou exerceront leur profession, les contrats ou avenants leur assurant l'usage de ce matériel et de ce local.
       Les mêmes obligations s'appliquent aux contrats et avenants ayant pour objet de transmettre sous condition résolutoire la propriété du matériel et du local.
       La communication prévue ci-dessus doit être faite dans le mois suivant la conclusion du contrat ou de l'avenant, afin de permettre l'application des articles L. 4121-2 et L. 4127-1.
       Tous les contrats et avenants dont la communication est exigée doivent être passés par écrit.
       Les contrats et avenants dont la communication est prévue ci-dessus doivent être tenus à la disposition du ministre chargé de la santé par le conseil départemental de l'ordre des médecins ou par le conseil départemental de l'ordre des chirurgiens-dentistes.
       Toute personne physique ou morale passant un contrat avec un médecin ou un chirurgien-dentiste doit le faire par écrit.`, 10, 200, {maxWidth: 190});
       doc.text(`2/4`, 105, 285, {align:'center',maxWidth: 190});
       doc.setPage(3);
       doc.setTextColor('#000000')
       doc.setFontSize(13);
       doc.addImage(img, 'png', 155, 5, 45, 13)
    doc.text(`Article L1453-1 - I.-Les entreprises produisant ou commercialisant des produits mentionnés au II de
    l'article L. 5311-1 à l'exception de ceux mentionnés aux 14°, 15° et 17° ou assurant des prestations associées à ces produits sont tenues de rendre publics, sur un site internet public unique, l'objet précis, la date, le bénéficiaire direct et le bénéficiaire final, et le montant des conventions qu'elles concluent avec :`, 10, 25, {maxWidth: 190});
    doc.text(`1° Les professionnels de santé relevant de la quatrième partie du présent code ;
    2° Les associations de professionnels de santé ;
    3° Les étudiants se destinant aux professions relevant de la quatrième partie du présent code ainsi que les associations et groupements les représentant ;
    4° Les associations d'usagers du système de santé ;
    5° Les établissements de santé relevant de la sixième partie du présent code ;
    6° Les académies, les fondations, les sociétés savantes et les sociétés ou organismes de conseil intervenant dans le secteur des produits ou prestations mentionnés au premier alinéa ;
    7° Les personnes morales éditrices de presse, de services de radio ou de télévision et de services de communication au public en ligne ;
    7° bis Les personnes qui, dans les médias ou sur les réseaux sociaux, présentent un ou plusieurs produits de santé, de manière à influencer le public ;
    8° Les éditeurs de logiciels d'aide à la prescription et à la délivrance ;
    9° Les personnes morales assurant ou participant à la formation initiale ou continue ou au développement professionnel continu des professionnels de santé mentionnés au 1° du présent I.
    Les entreprises produisant ou commercialisant des produits mentionnés aux 14°, 15° et 17° du II de l'article L. 5311-1 ou assurant des prestations associées à ces produits sont tenues de rendre publique l'existence des conventions relatives à la conduite de travaux d'évaluation de la sécurité, de vigilance ou de recherche biomédicale qu'elles concluent avec les bénéficiaires mentionnés aux 1° à 9° du présent I.
    Cette obligation ne s'applique pas aux conventions régies par les articles L. 441-3 et L. 441-9 du code de commerce et qui ont pour objet l'achat de biens ou de services par les personnes physiques ou morales mentionnées aux 1° à 9° du présent I auprès des entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 du présent code ou assurant des prestations associées à ces produits.
    I bis.-Les entreprises produisant ou commercialisant des produits mentionnés au II de l'article L. 5311-1 ou assurant des prestations associées à ces produits sont tenues de rendre publiques, au-delà d'un seuil fixé par décret, sur le site mentionné au I du présent article, les rémunérations versées à des personnes physiques ou morales dans le cadre des conventions mentionnées au même I.
    II.-La même obligation s'applique, au-delà d'un seuil fixé par décret, à tous les avantages en nature ou en espèces autres que les rémunérations mentionnées au I bis que les mêmes entreprises procurent, directement ou indirectement, aux personnes, associations, établissements, fondations, sociétés, organismes et organes mentionnés au I.
    II bis.-Les informations publiées sur le site internet public unique mentionné au I du présent article sont réutilisables, à titre gratuit, dans le respect de la finalité de transparence des liens d'intérêts et dans les conditions prévues à l'article L. 322-1 du code des relations entre le public et l'administration et, lorsque cette réutilisation donne lieu à un traitement de données, dans les conditions prévues par la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. 
    III.-Un décret en Conseil d'Etat, pris après avis de la Commission nationale de l'informatique et des libertés, fixe les conditions d'application du présent article, la nature des informations qui doivent être rendues publiques sur le site internet public unique, notamment l'objet précis et la date des conventions mentionnées au I, ainsi que les délais et modalités de publication et d'actualisation de ces informations.`, 10, 60, {maxWidth: 190});
    doc.text(`3/4`, 105, 285, {align:'center',maxWidth: 190});
    doc.setPage(4);
    doc.setTextColor('#000000')
    doc.setFontSize(13);
    doc.addImage(img, 'png', 155, 5, 45, 13)
    
    doc.text(`Il précise également les modalités suivant lesquelles les ordres des professions de santé sont associés à cette publication.
    Article L.1453-3, Est interdit le fait, pour les personnes mentionnées à l'article L. 1453-4, de recevoir des avantages en espèces ou en nature, sous quelque forme que ce soit, d'une façon directe ou indirecte, proposés ou procurés par les personnes mentionnées à l'article L. 1453-5.
    Selon l’article L.1453-4, l'interdiction prévue à l'article L. 1453-3 est applicable :
    1° Aux personnes exerçant une profession de santé réglementée par le présent code, aux ostéopathes et aux chiropracteurs mentionnés à l'article 75 de la loi n° 2002-303 du 4 mars 2002 relative aux droits des malades et à la qualité du système de santé et aux psychothérapeutes mentionnés à l'article 52 de la loi n° 2004-806 du 9 août 2004 relative à la politique de santé publique ;
    2° Aux étudiants en formation initiale se destinant à l'exercice de l'une des professions mentionnées au 1° et aux personnes en formation continue ou suivant une action de développement professionnel continu dans ce champ ;
    3° Aux associations qui regroupent des personnes mentionnées aux 1° et 2°, dont celles intervenant dans le champ de la formation de ces personnes, et notamment aux sociétés savantes et aux conseils nationaux professionnels mentionnés à l'article L. 4021-3 ;
    4° Aux fonctionnaires et agents des administrations de l'Etat, des collectivités territoriales et de leurs établissements publics ou de toute autre autorité administrative qui élaborent ou participent à l'élaboration d'une politique publique en matière de santé ou de sécurité sociale ou sont titulaires de pouvoirs de police administrative à caractère sanitaire.  `, 10, 25, {maxWidth: 190});
    doc.setFontSize(18);
    doc.text(`CONSERVATION DES DONNEES ET DROITS`, 105, 140, {align:'center',maxWidth: 190});
    doc.setFontSize(13);
    doc.text(`Les données personnelles que nous recueillons seront traitées de manière confidentielle et uniquement utilisées à des fins d'étude de marché. Elles pourront être conservées après la fin de l'étude pendant une durée 3 ans si vous n'avez répondu activement à aucune sollicitation par UserAdventure. Elles pourront être conservées pendant 2 ans par UseConcept. Vous pouvez demander à tout moment à avoir accès à ces données personnelles et à les faire modifier ou détruire. Vous pouvez également en demander la portabilité et la limitation du traitement. Veuillez nous contacter à cette adresse maxime.aldegheri@useradventure.fr dans ce cas. Vous avez le droit d'introduire une réclamation auprès d’une autorité de contrôle si votre demande n’est pas prise en charge dans le mois qui suit. (CNIL : https://www.cnil.fr). Notre Politique de confidentialité est consultable sur : https://www.useradventure.fr rubrique Politique de protection des données personnelles`, 10, 150, {maxWidth: 190});
    doc.text(`J’accepte de participer à l’entretien dans les conditions indiquées ci-dessus et d’être recontacter si certains points doivent être reclarifiés.`, 10, 210, {maxWidth: 190});
    doc.text(`Nom :`, 10, 225, {maxWidth: 190});
    doc.text(`Aldegheri Maxime`, 30, 225, {maxWidth: 190});
    
    doc.text(`Date : `, 10, 235, {maxWidth: 190});
    doc.text(`${moment(DateSignature).format('Do-MM-YYYY')}`, 30, 235, {maxWidth: 190});
    doc.text(`Signature : `, 10, 245, {maxWidth: 190});
    
    if(sig){
      doc.addImage(sig, 'png', 20, 235, 100, 35)
    }else{
    doc.setFont('BrittanySignature-normal');
    doc.setFontSize(28);
    doc.text(`${Nom.charAt(0).toUpperCase() + Nom.slice(1) + " " + Prenom.charAt(0).toUpperCase() + Prenom.slice(1)}`, 45, 258, {maxWidth: 190});
    }
    // var checkBox = new jsPDF.API.AcroFormCheckBox();
    // checkBox.fieldName = "CheckBox1";
    // checkBox.Rect = [50, 10, 5, 5];
    // doc.addField(checkBox);
    // var checkBox = new jsPDF.API.AcroFormCheckBox();
    //     checkBox.fieldName = "CheckBox2";
    //     checkBox.x = 150;
    //     checkBox.y = 270;
    //     checkBox.width = 30;
    //     checkBox.height = 30;
    //     checkBox.fontSize = 20;
    //     checkBox.value = 'Off'; // or On
    //     checkBox.appearanceState = 'Off'; // or On
    //     checkBox.color = 'red';
    //     doc.addField(checkBox);
    //  doc.setFont('Times')
    doc.setFontSize(13); 
    doc.setFont('Times')
    doc.text(`4/4`, 105, 285, {align:'center',maxWidth: 190});
    const blob = doc.output('blob');
    //setblob(blob)  
    //doc.save("a5.pdf");
    
    var reader = new FileReader();
    reader.readAsDataURL(blob); 
    reader.onloadend = function() {
      var base64data = reader.result;   
      const getDataPart =  base64data.split(',')[1]; 
      console.log('blob',blob);
      console.log('base64data',getDataPart.slice(0,-1));
      seturl(getDataPart.slice(0,-1));
      update(IDsign,getDataPart.slice(0,-1))
    }
    
    }

    let sigPad = useRef()

    const signat = () => {
    
      createPDF1(SigImg) 

   
    }

    const update = (IDsign, url1) => {

    fetch('https://www.useradventure.net/siteHS_json_update_sign.php', {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ID: IDsign,  ImgSigne: url1})
    })
      .then((response) => response.json())
      .then((response) => { console.log('response', response)

        })
        .then(() => fin())
        .catch((error) => console.log('error',error))
    }

    const fin = () => {
      setModalVisible(false)
      MAJ()
    }

    const clear = () => {
      sigPad.current.clear() 
    }
    
    const etat = () => {
     console.log(doc.getKeyValueListForStream) 
    }
    
   
    const onChangecheckbox = (item1)  => {
      console.log('item',item1)
      const data =  [...ArrayCheckbox] 
      if(data[item1].checked == true){

        data[item1].checked = false
      }else{
        data[item1].checked = true
      }
      
    
        setArrayCheckbox(data)
        console.log('newData',ArrayCheckbox)
        if(data.filter(x => x.checked).length == ArrayCheckbox.length){
          setdisabledcolor('rgba(229, 113, 165, 1)')
          setdisabled(true)
        } else {
          setdisabledcolor('#DCDCDC')
          setdisabled(false)
        }
    }

    const continu = () => {
      if(disabled){

         setModalVisible(true)
      }else{
        alert('Veuillez cocher toutes les cases, Merci')
      }
     
     }


     const download = () => {
      fetch('https://www.useradventure.net/siteHS_json_get_sign.php', {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ IDUser: IDUser,  Secure: Secure, ID_Etude: ID_Etude})
      })
        .then((response) => response.json())
        .then((response) => { console.log('response', response)
         
        console.log(response[0].ImgSigne)
        var link = document.createElement('a');
        link.href = 'data:application/octet-stream;base64,' + response[0].ImgSigne + '='
        link.setAttribute('download', 'conventionHS_' + Nom.charAt(0).toUpperCase() + Nom.slice(1) + "_" + Prenom.charAt(0).toUpperCase() + Prenom.slice(1) + "_" + moment(DateSignature).format('Do-MM-YYYY') + '.pdf');
        document.body.appendChild(link);    
        link.click();
        document.body.removeChild(link);

          })
          .catch((error) => console.log('error',error))
      'data:application/pdf;base64,' + SigImg

     }
    
   
    
      return (<>  

{ resultat  == 0 ? <View style={{flex: 4 }} >
    <View style={{flex: 2 }} > </View>
    <View style={{flex: 2, backgroundColor:'#3F78E0' }} > 
 
    <Modal
animationType="fade"
transparent={true}
visible={true}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={{ flex: 1,
    justifyContent: "center",
    alignItems: "center",
    }} onPress={() => setModalVisible(false)}>
<View style={styles.modalView}>
<View style={{
 
      padding:30,
      
       }}>
  <MaterialCommunityIcons name={"hand-wave"} color={'rgba(47, 166, 154, 1)'} size={48} style={{textAlign:'center'}} />
  <Text style={{fontSize:20,color:'black',fontWeight:'bold',marginVertical:15, textAlign:'center'}}>Bonjour !</Text>
  <Text style={{fontSize:16,color:'black',marginVertical:15}}>Vous n'avez pas de document à signer</Text>
  <Text style={{fontSize:16,color:'black',marginVertical:15}}>Bonne journée !</Text>
  
  </View>
</View>
</View>
</Modal>
    </View>
    </View> : 
    docu  && Signed == 0 ? <View style={{flex: 1}} >   
  <View style={{flexDirection: 'row',flex:1, backgroundColor: 'rgba(47, 166, 154, 1)', justifyContent:'center'}}>
  {/* {SigImg ? <iframe  style={{background:"white"}} width='100%' height='100%' src={'data:application/pdf;base64,' + SigImg + '#toolbar=0' }></iframe>   : undefined } */}
    <View style={{ backgroundColor: 'rgba(47, 166, 154, 1)'}}> 
     
   <ScrollView>
     { Array.apply(null, Array(4)).map((val, idx) => (
      <View style={{ backgroundColor: 'white', margin:10, padding: Device.deviceType == 3 ? 40 : 20, height: Device.deviceType == 3 ? 1200 : 550, width : Device.deviceType == 3 ? 820 : 348}}>
      <View style={{ alignItems:'flex-end', marginTop: Device.deviceType == 3 ? -20 : -10}} >
      <Img style={{width: '30%', height : Device.deviceType == 3 ? 60 : 30 }} source={require("../assets/logo_HealthSquad_complet.png")} />
    </View>
    {/* {  Document1 && Document1.map((item,index) => ( 
      <View key={index}>
      {(() => {  
                   switch (item.type) {
                    case "text":   return ( 
                      <View style={{position:'absolute', top:item.Top, left: item.Left}}>
                      <Text style={{textAlign: item.Align, fontFamily: item.Font , fontSize: Device.deviceType == 3 ? item.FontSize : item.FontSize / 2.4, fontWeight: item.Bold == true ? 'bold' : 400 }}>{item.Value}</Text>
                     </View>); 
                    case "checkbox":   return (  
              <CheckBox
            textStyle={{fontSize:Device.deviceType == 3 ? item.FontSize : item.FontSize / 2.4, fontWeight:'normal'}}
            containerStyle={{ backgroundColor:"transparent", borderWidth:0, padding:0, margin:0, marginLeft: Device.deviceType == 3 ? item.MarginLeft : item.MarginLeft / 2, marginTop: Device.deviceType == 3 ? item.MarginTop : item.MarginTop / 2}}
            checked={check1}
            checkedColor='#3F78E0'
            size={Device.deviceType == 3 ? item.FontSize : item.FontSize / 2.4}
            onPress={() => setCheck1(!check1)}
                        />)
                    default:      return <Text>error</Text>;
                  }})()}
     </View>))} */}

     {  Document1 && Document1.filter(x => x.Page == idx + 1).map((item,index) => ( 
      
      <View key={index}>
      {(() => {  
                   switch (item.type) {
                    case "text":   return ( 
                  <ImageBackground resizeMode="repeat" source={item.image != '' ? item.image : undefined} style={{width: '100%', height: '100%'}}>
                      <Text style={{textAlign: item.Align, fontFamily: item.Font, marginLeft: Device.deviceType == 3 ? item.MarginLeft : item.MarginLeft / 2, marginTop: Device.deviceType == 3 ? item.MarginTop : item.MarginTop / 2 , fontSize: Device.deviceType == 3 ? item.FontSize : item.FontSize / 2.4, fontWeight: item.Bold == true ? 'bold' : 400 }}>{item.Value}</Text>
                  </ImageBackground>
                    ); 
                    case "checkbox":   return (  
                       ArrayCheckbox.map((item1, i) => (   
                        item1.id == index ?  <CheckBox
              
            textStyle={{fontSize:Device.deviceType == 3 ? item.FontSize : item.FontSize / 2.4, fontWeight:'normal'}}
            containerStyle={{ backgroundColor:"transparent", borderWidth:0, padding:0, margin:0, marginLeft: Device.deviceType == 3 ? item.MarginLeft : item.MarginLeft / 2, marginTop: Device.deviceType == 3 ? item.MarginTop : item.MarginTop / 2}}
            checked={item1.checked}
            checkedColor='#3F78E0'
            size={Device.deviceType == 3 ? item.FontSize : item.FontSize / 2.4}
            onPress={() => onChangecheckbox(i)}
             /> : undefined )) )
                    default:      return <Text>error</Text>;
                  }})()}
     </View>))}
     <View style={{flex: 1, alignItems:'center' }} />
     <View style={{flexDirection:'row', flexDirection:'row-reverse'}}>
       <View style={{flex: 1, alignItems:'center' }} >
       <Text style={{fontSize: Device.deviceType == 3 ? 14 : 7}}>{idx + 1}/4</Text>
    </View>
    </View>
      </View>))}
      </ScrollView>
      </View>
     
    </View>
       <View style={{flexDirection:'row', flexDirection:'row-reverse', height:70, }}>
       <View style={{flex: 1, alignItems:'center' }} >
       <Pressable style={{width:100, alignItems:'center', padding: 15,margin: 10, borderRadius:25,backgroundColor: disabledcolor , elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}} onPress={()=>continu()}>
          <Text style={{fontWeight:'bold', color:'white', fontSize:16}}>Signer</Text>
        </Pressable>
    </View>
 
    </View>
    </View> : 
    <View style={{flex: 4 }} >
    <View style={{flex: 2 }} > </View>
    <View style={{flex: 2, backgroundColor:'#3F78E0' }} > 
 
    <Modal
animationType="fade"
transparent={true}
visible={true}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={{ flex: 1,
    justifyContent: "center",
    alignItems: "center",
    }} onPress={() => setModalVisible(false)}>
<View style={styles.modalView}>
<View style={{
 
      padding:30,
      
       }}>
  <MaterialCommunityIcons name={"check-decagram"} color={'rgba(47, 166, 154, 1)'} size={48} style={{textAlign:'center'}} />
  <Text style={{fontSize:20,color:'black',fontWeight:'bold',marginVertical:15, textAlign:'center'}}>C'est signé !</Text>
  <Text style={{fontSize:16,color:'black',marginVertical:15}}>Maxime, votre signature a bien été prise en compte</Text>
  <Text style={{fontSize:16,color:'black',marginVertical:15}}>Vous pouvez consulter et télécharger le document directement ci-dessous ou en cliquant sur le lien que vous avez reçu par e-mail.</Text>
  <Pressable style={{justifyContent:'center', alignContent:'center', padding: 10,marginTop: 25, borderRadius:25,backgroundColor: 'rgba(229, 113, 165, 1)', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}  onPress={() => download()} >
    <View style={{flexDirection: "row",justifyContent:'center'}}>
    <Text style={{fontWeight:'bold', color:'white', fontSize:16}}>Télécharger le document</Text>
    <MaterialCommunityIcons name={"tray-arrow-down"} color={"white"} size={18} style={{ marginLeft: 5}} />
    </View>
    </Pressable> 
  </View>
</View>
</View>
</Modal>
    </View>
    </View>
    }
        {/* <Text>
        {Device.deviceType}
      </Text>
        <Pressable onPress={() => { WebBrowser.openBrowserAsync(url).then(()=>{console.log("tt")})} } style={{ height:50,justifyContent: 'center', alignItems: 'center', borderRadius:5,elevation: 15, backgroundColor: '#FFB217',shadowColor: 'black', shadowOpacity: 0.2, shadowOffset: { height: 10 }}} >GGG </Pressable>
        {/* <iframe type='application/pdf' allow="fullscreen" scrolling="auto" id="myIframe" onLoad={()=> {console.log("tt")}} src={url} width={width} height={height} frameborder="0" marginheight="0" marginwidth="0">Chargement…</iframe> */}
  {/* <iframe  style={{background:"white"}} width='100%' height='100%' src={url + '#toolbar=0' }></iframe> 
  <iframe src={url + '#toolbar=0&embedded=true' } frameborder="0" id="myiframe" width='100%' height='100%'></iframe> */}
 {/* 'rgba(229, 113, 165, 1)'  '#3F78E0' */}
  
  
 
   
 {/* <SignatureCanvas ref={sigPad} penColor='black' onEnd={()=> setSigImg(sigPad.current.toDataURL()) }
    canvasProps={{width: 800, height: 200}} style={{borderWidth: 1}}/>  */}
  {/* <Pressable onPress={()=>signat()}>
          <Text>save</Text>
        </Pressable>
        <Pressable onPress={()=>clear()}>
          <Text>clear</Text>
        </Pressable>
        <Pressable onPress={()=>etat()}>
          <Text>etat</Text>
        </Pressable> */}
         {/* <View style={{flex:1, alignItems:'center', justifyContent:'center', borderWidth: 1}}>     
    <Text style={{fontFamily:'BrittanySignature', fontSize:38}}>Aldegheri Maxime</Text>
   </View> */}
   <Modal
animationType="fade"
transparent={true}
visible={modalVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={styles.centeredView1} onPress={() => setModalVisible(false)}>
<View style={styles.modalView}>

<View style={styles.modalVText}>
  <Text style={styles.modalText}>Il ne vous reste plus qu'à signer !</Text>
  <Text style={styles.modalText}>Voici la signature qui apparaîtra sur le document :</Text>
 <View style={{flex:1, alignItems:'center', justifyContent:'center', borderWidth: 1, borderRadius:5, padding:20}}>   
 {SigImg ?  <Img style={{width: '50%', height : Device.deviceType == 3 ? 100 : 50 }} source={SigImg} /> :
    <Text style={{fontFamily:'BrittanySignature', fontSize:38}}>{Nom.charAt(0).toUpperCase() + Nom.slice(1) + " " + Prenom.charAt(0).toUpperCase() + Prenom.slice(1)}</Text>}
    <Pressable style={{justifyContent:'center', alignContent:'center', padding: 10,marginTop: 30, borderRadius:25,backgroundColor: 'rgba(229, 113, 165, 1)', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}  onPress={() => signat()} ><Text style={{fontWeight:'bold', color:'white', fontSize:16}}>Valider pour signer</Text></Pressable>
   </View>
   
  <View style={{flexDirection: "row",justifyContent:'center'}}>
  {SigImg ?<Pressable style={{justifyContent:'center', alignContent:'center', padding: 10,marginTop: 30, borderRadius:25,backgroundColor: 'rgba(229, 113, 165, 1)', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}  onPress={() => setSigImg('')} >
    <View style={{flexDirection: "row",justifyContent:'center'}}>
    <MaterialCommunityIcons name={"draw-pen"} color={"white"} size={18} style={{ marginRight: 5}}/>
    <Text style={{fontWeight:'bold', color:'white', fontSize:16}}> Recommencer ma signature</Text>
    </View>
    </Pressable>: <Pressable style={{justifyContent:'center', alignContent:'center', padding: 10,marginTop: 25, borderRadius:25,backgroundColor: 'rgba(229, 113, 165, 1)', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}  onPress={() => setModalVisible2(true)} >
    <View style={{flexDirection: "row",justifyContent:'center'}}>
    <MaterialCommunityIcons name={"draw-pen"} color={"white"} size={18} style={{ marginRight: 5}} />
    <Text style={{fontWeight:'bold', color:'white', fontSize:16}}> Dessiner ma signature</Text>
    </View>
    </Pressable> 
     }
  </View> 
  </View>
</View>
</View>
</Modal>

<Modal
animationType="fade"
transparent={true}
visible={modalVisible2}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<View style={styles.centeredView1} onPress={() => setModalVisible(false)}>
<View style={styles.modalView}>
<View style={styles.modalVText}>
  <Text style={styles.modalText}>Dessiner ma signature</Text>
  <Text style={styles.modalText}>Veuillez dessiner votre signature dans l'espace ci-dessous :</Text>
 <View style={{flex:1, alignItems:'center', justifyContent:'center', borderWidth: 1, borderRadius:5, padding:20}}>     
 <SignatureCanvas ref={sigPad} penColor='black' onEnd={()=> {setSigImg(sigPad.current.toDataURL()),console.log(sigPad.current.toDataURL())} }
    canvasProps={{width: 450, height: 200}} style={{borderWidth: 1}}/> 
   </View>
   <Text style={styles.modalText}>Je comprends que ceci est une représentation légale de ma signature.</Text>
    </View>
  <View style={{flexDirection: "row",justifyContent:'center',marginBottom: 20, justifyContent:'space-around'}}>
  <Pressable style={{justifyContent:'center', alignContent:'center', padding: 10, borderRadius:25,backgroundColor: 'rgba(229, 113, 165, 1)', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}  onPress={()=>clear()} > <Text style={{fontWeight:'bold', color:'white', fontSize:16}}>Effacer la signature</Text></Pressable>
  <Pressable style={{justifyContent:'center', alignContent:'center', padding: 10, borderRadius:25,backgroundColor: 'rgba(229, 113, 165, 1)', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.3,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}}  onPress={() => setModalVisible2(false)} > <Text style={{fontWeight:'bold', color:'white', fontSize:16}}>Valider la signature</Text></Pressable>
  </View>
</View>
</View>
</Modal>
   </>
  
  );
}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
   
    
  },   
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 25,
},
  
  centeredView1: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"rgba(255,255,255,0.5)"
  },
  modalView: {width: Device.deviceType == 3 ? 600 : Dimensions.get('window').width - 30,
    margin: 30,
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 0,
    elevation: 20 , 
    borderColor:'#3F78E0',
    borderWidth: 2 ,
    shadowColor: "#000000", 
    shadowOpacity: 0.5,
    shadowRadius: 5, 
    shadowOffset: { height: 5, width:5 }
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   TextInput: {
    height: 45,
    marginVertical:10,
    padding: 10,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 18
  },
  TextInput2: {
    height: 45,
    marginVertical:0,
    padding: 0,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 18
  },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
  fontSize:16,
 color:'black',
 fontWeight:'bold',
 marginVertical:15

   },
   modalTextbold: {
    fontWeight:'bold'
    }, 
    pdf: {
      flex:1,
      width:Dimensions.get('window').width,
      height:Dimensions.get('window').height,
  },
    modalVText: {
      padding:30,
     // alignItems: "center"
       }

  });

export default Signature