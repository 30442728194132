import React, { useState, useEffect, Component, useRef } from 'react';
import { SafeAreaView, Animated, StyleSheet, View, FlatList, Text, TextInput, Image, TouchableOpacity, StatusBar, Dimensions, ScrollView, Modal, ImageBackground, Pressable} from 'react-native';
import { Drawer, Button,MD3LightTheme as DefaultTheme, IconButton,DataTable, List} from 'react-native-paper';

const ModalPartage =  ({Text1, Text2, isVisible, onSubmit, onCancel }) => { 

  const [Email, setEmail] = useState('');

return(
  
  <Modal
animationType="fade"
transparent={true}
visible={isVisible}
onRequestClose={() => {
Alert.alert("Modal has been closed.");
}}
>
<Pressable style={styles.centeredView} onPress={() => onCancel()}>
<Pressable style={styles.modalView}>
<View style={styles.modalVText}>
  <Text style={styles.modalText}>{Text1}</Text>
    </View>
 
  <View style={{flexDirection: "row",justifyContent:'center'}}>

    <Button style={{ elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}} buttonColor='white'  textColor='#3F78E0' labelStyle={{fontWeight:600}} mode="contained"  onPress={() => onSubmit()} >{Text2}</Button>
  </View>
</Pressable>
</Pressable>
</Modal>
);

}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modalView: {
    margin: 30,
    width:350,
    backgroundColor: '#3F78E0',
    borderRadius: 5,
    padding: 45,
    elevation: 20 , 
    shadowColor: "#000000", 
    shadowOpacity: 0.5,
    shadowRadius: 5, 
    shadowOffset: { height: 5, width:5 }
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   TextInput: {
    height: 45,
    marginVertical:10,
    padding: 10,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 16
  },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
  fontSize:16,
 color:'white',
 fontWeight:'bold'
   },
   modalTextbold: {
    fontWeight:'bold'
    },
    modalVText: {
      paddingBottom:20,
     // alignItems: "center"
       }

  });

  export default ModalPartage;