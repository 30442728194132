import React, {useState, useMemo, useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import {Form, Button, Col, Row, Container, Image, InputGroup, ListGroup, ProgressBar} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import u75 from '../src/logo_HealthSquad_complet.png';

const Confirmation = () => {

    function $_GET(param) {
        var vars = {};
        window.location.href.replace( location.hash, '' ).replace( 
          /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
          function( m, key, value ) { // callback
            vars[key] = value !== undefined ? value : '';
          }
        );
      
        if ( param ) {
          return vars[param] ? vars[param] : null;	
        }
        return vars;
      }

    const color1 = "rgba(217, 228, 249, 1)";
    const color2 = "rgba(241, 246, 255, 1)";
    const { height, scale, width } = useWindowDimensions();

    var Email = decodeURI( $_GET( 'Email' ) )
    var name = decodeURI( $_GET( 'name' ) )
    var Prenom = decodeURI( $_GET( 'Prenom' ) )

    function validCommand3() {
        document.location = "https://www.healthsquad.fr";
        //window.open('https://www.healthsquad.fr/')
      }

  return (
    <Container fluid style={{flex:1, background: `linear-gradient(to right,  ${color1} 60%,${color2} 90%)`, padding:0, margin:0}}>
    <Col style={{background: 'white', padding:0, margin:0}}  className="text-center" >
    <Button variant="outline-light" style={{borderWidth:0}} onClick={() => {
             window.open('https://www.healthsquad.fr/', '_blank')}}>
    <Image src={u75} style={{height: width > 800 ? 100 : 70}}/>
             </Button>
    </Col>
    
    <Col md={{ span: 10, offset:1  }} lg={{ span: 10, offset: 1 }} style={{marginTop:20,background: 'rgba(255, 255, 255, 0.35)',borderRadius:15, padding:20,marginBlock:40}}>
   <Col style={{justifyContent:'center',alignItems:'center'}}>
      <Col className="text-center" style={{fontSize: 18,color: 'black',paddingInline:30, fontWeight:'700'}}>Felicitation {Prenom} !</Col>
      <Col className="text-center" style={{fontSize: 18,color: 'black',paddingInline:30, fontWeight:'700'}}>Vous faites désormais parti de la <span className="gradient-text" style={{fontSize: 22,fontWeight: 700, fontStyle:'italic'}}>Health Squad</span> </Col>    
        <Col  style={{display:'flex', justifyContent:'center'}} >
        <Button variant="outline-light"  onClick={()=>validCommand3()} style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:70, paddingBlock:10, marginTop:20, fontSize:24}}>
      OK  </Button>
            </Col>               
        
      
       
        
      </Col>
    </Col>
  </Container>
  )
}

export default Confirmation