import React, {useState, useMemo, useEffect} from 'react';
import { Container, Row, Col, Image, Ratio, Modal,  Accordion, ListGroup, Button  } from 'react-bootstrap';
import logo from '../src/logo_HealthSquad_complet.png';

const Modal3 = ({show, Quit, validCommand3}) => {


    return (
        <>
        <Modal show={show} onHide={Quit} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header style={{justifyContent:'center',alignItems:'center', borderWidth:0}}>
        
         <Image src={logo}  fluid alt="logoHealthSquad"/>
        
        </Modal.Header>
        <Modal.Body style={{background: 'white'}}>
        <Col className="text-center" style={{fontSize: 18,color: 'black',paddingInline:30, fontWeight:'700'}}>Felicitation ! Vous faites désormais parti de la <span className="gradient-text" style={{fontSize: 22,fontWeight: 700, fontStyle:'italic'}}>Health Squad</span> </Col>
        <Col className="text-center" style={{fontSize: 18,color: 'black',paddingInline:30, fontWeight:'700', marginTop:20}}>Un mail de confirmation vous a été envoyé sur votre boîte mail.</Col>      
        <Col  style={{display:'flex', justifyContent:'center'}} >
            </Col>               
        
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center',alignItems:'center', borderWidth:0}}>
        <Button variant="outline-light"  onClick={()=>validCommand3()} style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:70, paddingBlock:10, marginTop:20, fontSize:24}}>
      OK  </Button>
                            
      </Modal.Footer>
      </Modal>
        </>
    )
}

export default Modal3

