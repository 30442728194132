import React, {useState, useMemo, useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import * as Linking from 'expo-linking';
import { NavigationContainer, useRoute, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets, CardStyleInterpolators } from '@react-navigation/stack';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {PaperProvider} from 'react-native-paper';
import Inscription from './screens/Inscription';
import Confirmation from './screens/Confirmation';
import Partage from './screens/Partage';
import Password from './screens/Password';
import Signature from './screens/Signature';

export default function App() {

  const Stack = createNativeStackNavigator();
  const prefix = Linking.createURL("/");

  const config = {
    screens: {
     
      Inscription: '/Inscription.html',
      Confirmation: '/Confirmation.html',
      Partage: '/Partage.html',
      Password: '/Password.html',
      Signature: '/Signature.html',
    },
  }


  
  const linking = {
    prefixes: [prefix],
    config,
  };



  return (
    <PaperProvider>
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <StatusBar style="dark" />
      <Stack.Navigator>
        <Stack.Screen name="Inscription" component={Inscription} options={{headerShown: false}}/>
        <Stack.Screen name="Confirmation" component={Confirmation} options={{headerShown: false}}/>
        <Stack.Screen name="Partage" component={Partage} options={{headerShown: false}}/>
        <Stack.Screen name="Password" component={Password} options={{headerShown: false}}/>
        <Stack.Screen name="Signature" component={Signature} options={{headerShown: false}}/>
       </Stack.Navigator>
   </NavigationContainer>
   </PaperProvider>

    
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  tinyLogo: {
    width: 50,
    height: 50,
  },
});
