import React, { useState, useEffect, Component, useRef, useLayoutEffect } from 'react';
import { StyleSheet, Text, View , Dimensions, Animated, useWindowDimensions, Image, TouchableOpacity, Platform, FlatList, ScrollView, SafeAreaView} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {PaperProvider, Drawer ,TextInput, Button, MD3LightTheme as DefaultTheme, IconButton, DataTable, List, ProgressBar, MD3Colors, Avatar, Tooltip} from 'react-native-paper';
import { Icon, Divider, SearchBar, CheckBox, Slider} from 'react-native-elements';
import moment from 'moment';
import 'moment/locale/fr';
import Calendar3 from '../components/Calendar3';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';

const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Partage = () => {

const [check1, setCheck1] = useState(false);
const [next, setNext] = useState(false);
const [DATA, setDATA] = useState([]);
const [itemP, setItemP] = useState([]);
const [sort, setSort] = useState();
const [password, setpassword] = useState('');
const [Email, setEmail] = useState('');
const [Nom, setNom] = useState('');
const [step, setStep] = useState(1);
const [Code, setCode] = useState('');
const [expanded1, setExpanded1] = useState(true);
const [expanded2, setExpanded2] = useState(true);

function $_GET(param) {
  var vars = {};
  window.location.href.replace( location.hash, '' ).replace( 
    /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
    function( m, key, value ) { // callback
      vars[key] = value !== undefined ? value : '';
    }
  );

  if ( param ) {
    return vars[param] ? vars[param] : null;	
  }
  return vars;
}

var ID_Dossier = decodeURI( $_GET( 'ID_Dossier' ) )
var ID_Client = decodeURI( $_GET( 'ID_Client' ) )


const week = (determinedate) => {

  determinedate.setFullYear(determinedate.getFullYear(), determinedate.getMonth(), determinedate.getDate());
  var D = determinedate.getDay();
  if(D == 0) D = 7;
  determinedate.setDate(determinedate.getDate() + (4 - D));
  var YN = determinedate.getFullYear();
  var ZBDoCY = Math.floor((determinedate.getTime() - new Date(YN, 0, 1, -6)) / 86400000);
  var WN = 1 + Math.floor(ZBDoCY / 7);
  return WN;
}

const Lieux = [
  {id:1, title: "Useconcept"},
  {id:2, title: "Chez le Client"},
  {id:3, title: "En ligne"},
  {id:4, title: "Au Cabinet"},
  {id:5, title: "Chez le Testeur"},
  {id:6, title: "Dans une Salle"}
];

useEffect(() => {
  //localStorage.setItem(`mailCARDIAWAVE1`, '')
 // localStorage.setItem(`codeCARDIAWAVE1`, '')
  MAJ1()
 
   
    
},[]);

const verif2 = (item, code1) => { 
  fetch('https://www.useradventure.net/saas_json_data_verfi_partage.php', {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ID_Dossier: ID_Dossier, Email:item, Password: code1})
  })
    .then((response) => response.json())
    .then((response) => { 
      if(response.length > 0){
        MAJ()
        setSort(response[0].ID)
      }else{
        alert('Code de vérification incorrect')
      }
      console.log('sort',response)})
      .catch(() => alert('Code de vérification incorrect'))
}


const MAJ1 = () => {
fetch('https://www.useradventure.net/saas_json_data_dossier.php', {
  method: 'post',
  headers: {
    'Accept': 'application/json',  // It can be used to overcome cors errors
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({ ID_Dossier: ID_Dossier})
})
  .then((response) => response.json())
  .then((response) => { 
    setNom(response[0].Nom_Dossier)
    setItemP(response)
    if(localStorage.getItem(`mail${response[0].Nom_Dossier}${ID_Dossier}`)){
      setEmail(localStorage.getItem(`mail${response[0].Nom_Dossier}${ID_Dossier}`))
      setCode(localStorage.getItem(`code${response[0].Nom_Dossier}${ID_Dossier}`))
      verif2(localStorage.getItem(`mail${response[0].Nom_Dossier}${ID_Dossier}`),localStorage.getItem(`code${response[0].Nom_Dossier}${ID_Dossier}`))}
  })
    .catch((error) => alert(error))
  }

const MAJ = () => {
  const newt1 =[]
  fetch(`https://www.useradventure.net/saas_json_data_all_rdv.php`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',  // It can be used to overcome cors errors
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ID_Client: ID_Client, ID_Dossier: ID_Dossier})
  })
    .then((response) => response.json())
    .then((response) => { 
     
      for (var i = 0; i < response.length; i++) {
       newt1.push({ID_Rdv: response[i].ID_Rdv,id: response[i].Destinataires,Nom_Projet:response[i].Nom_Projet, Sexe: response[i].Sexe, Age: response[i].Date_de_naissance, Profession: response[i].Poste, date: response[i].Q_2, hours: response[i].Q_3.replace("H", ':') , session: response[i].Nom_Etude, Exp: response[i].ID_Exp, Lieux_Rdv: response[i].Lieux_Rdv, Adresse_Rdv: response[i].Adresse_Rdv, Duree_Rdv: response[i].Duree_Rdv})
      }

     newt1.sort(function (a, b) {
        return (a.date.localeCompare(b.date)) || (a.hours.substr(0,a.hours.indexOf(":")))-(b.hours.substr(0,b.hours.indexOf(":")));
       });
     
    console.log('rdv',response)
    setDATA(newt1)})
    .catch((error) => alert(error))

     

}

const result2 = (ev) => { 
  for(var i = 0; i < Lieux.length; i++)
  {
    if(Lieux[i].id == ev)
    {
      return Lieux[i].title;
    }
  }}

  const Password1 = () => { 
    var ok = '123456789';
    var pass = '';
    var longueur = 6;
    for(let i=0;i<longueur;i++){
        var wpos = Math.round(Math.random()*ok.length);
        pass+=Number(ok.substring(wpos,wpos+1));
    }
       
    //console.log('pass',pass)
     //   setpassword(pass)
     return pass;
  }

  const run = async (message) => {
    const response = await mailchimp.messages.sendTemplate({
      template_name: "SendVerifSaas",
      "template_content": [{}],
      message: message,
    });
    console.log(response);
   
  };


  const sendPass = (item, pass) => {  
   if(item){
    setNext(true)
 fetch('https://www.useradventure.net/saas_json_update_lien_partage.php', {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ID_Dossier: ID_Dossier, Email:item, Password: pass})
    })
      .then((response) => response.json())
      .catch((error) => alert(error))

        const message = {
          from_name: "HealthSquad Notifications",
          from_email: "contact@healthsquad.fr",
          subject: `${pass} constitue votre code de vérification`,
          to: [
            {
              email: item,
              type: "to"
            }
          ],
          "merge_vars": [
            {
                "rcpt": item,
                "vars": [
                
                  {
                    "name": "PASS",
                    "content": pass
                  }
                 
                  
                ]
            }
        ]
        }
      run(message)
      }else{alert('Renseigner votre Email!')}
  }


  const verif = (item, code1) => { 
    console.log('email',item)
    console.log('code1',code1)
    console.log('ID_Dossier',ID_Dossier)
    if(check1){
      localStorage.setItem(`mail${Nom}${ID_Dossier}`, item)
      localStorage.setItem(`code${Nom}${ID_Dossier}`, code1)
    }
    fetch('https://www.useradventure.net/saas_json_data_verfi_partage.php', {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ID_Dossier: ID_Dossier, Email:item, Password: code1})
    })
      .then((response) => response.json())
      .then((response) => { 
        if(response.length > 0){
          MAJ()
          setSort(response[0].ID)
        }else{
          alert('Code de vérification incorrect')
        }
        console.log('sort',response)})
        .catch(() => alert('Code de vérification incorrect'))
  }

  
  
      return (
       
        sort == undefined  ?
        <LinearGradient colors={['rgba(217, 228, 249, 1)','rgba(241, 246, 255, 1)']} start={[0.0, 1.4]} end={[1.4, 1.4]}  style={{flex : 1,flexDirection: 'row', borderTopLeftRadius:0,paddingHorizontal: 0, shadowColor: 'black', shadowOpacity: 0.4, shadowRadius: 7, shadowOffset: { height: 0, width:-7 }}}>
     <View style={styles.centeredView} >
  <Image style={{ width: '100%', resizeMode: 'contain',height :100 }} source={require("../assets/logo_HealthSquad_complet.png")} /> 
<View style={styles.modalView}>
<View style={{backgroundColor:'rgba(217, 228, 249, 0.5)', padding: 35, paddingHorizontal:30,justifyContent: "center",alignItems: "center", shadowColor: 'black', shadowOpacity: 0.4, shadowRadius: 7, shadowOffset: { height: 2, width:0 }}}>
<Text style={{fontSize:20,fontWeight:'bold', color:'white'}}>{!next ? `Vérification d'Identité` : 'Entrer le Code de Vérication'}</Text>
</View>
<View style={{padding: 25, paddingHorizontal:45,justifyContent: "center"}}>
  <Text style={styles.modalText}>Vous avez reçu un lien sécurisé vers :</Text>
</View>
<View style={{flexDirection:'row',padding: 15, paddingHorizontal:45,alignItems: "center"}}>
<MaterialCommunityIcons name={"calendar-month"} color={"pink"} size={40} />
  <Text style={{fontSize:20,color:'white',fontWeight:'bold'}}> Planning {Nom}</Text>
    </View>
 {!next ?  <> 
    <View style={{padding: 25, paddingHorizontal:45,justifyContent: "center"}}>
  <Text style={styles.modalText}>Pour ouvrir ce lien sécurisé, vous devrez saisir l'adresse e-mail avec laquelle cet élément a été partagé.</Text>
</View>
  <View style={{paddingHorizontal:45,justifyContent: "center"}}>
  <TextInput
           style={styles.TextInput2}
           placeholder='Entrer Email'
            onChangeText={(text) => setEmail(text)}
            value={Email}
             autoComplete="email"
          />
 </View>
  <View style={{padding: 15, paddingHorizontal:45,justifyContent: "center"}}>
    <Button style={{ elevation: 20, borderRadius:5 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}} buttonColor='white'  textColor='#3F78E0' labelStyle={{fontWeight:600, fontSize:18}} mode="contained"  onPress={() => sendPass(Email, Password1())} >Suivant</Button>
  </View>
  <View style={{paddingBottom: 25, paddingHorizontal:45,justifyContent: "center"}}>
  <Text style={styles.modalText}>En cliquant sur Suivant, vous .....</Text>
</View></> : 
    <> 
    <View style={{padding: 25, paddingHorizontal:45,justifyContent: "center"}}>
  <Text style={styles.modalText}>Pour ouvrir ce lien, entrez le code que nous venons d'envoyer par e-mail à {Email}</Text>
  <Text style={{fontSize:20,color:'white',fontWeight:'bold', textDecorationLine:'underline'}} onPress={() => sendPass(Email, Password1())}>Renvoyer.</Text>
</View>
  <View style={{paddingHorizontal:45,justifyContent: "center"}}>
  <TextInput
           style={styles.TextInput2}
           placeholder='Entrer Code de Vérification'
            onChangeText={(text) => setCode(text)}
            value={Code}
           
          />
 </View>
  <View style={{padding: 15, paddingHorizontal:45,justifyContent: "center"}}>
    <Button style={{ elevation: 20, borderRadius:5 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 }}} buttonColor='white'  textColor='#3F78E0' labelStyle={{fontWeight:600, fontSize:18}} mode="contained"  onPress={() => verif(Email, Code)} >Vérifier</Button>
  </View>
  
  <View style={{flexDirection:'row', paddingHorizontal:45,alignItems: "center", paddingBottom:10}}>
<CheckBox
            textStyle={{fontSize:22, fontWeight:'normal'}}
            containerStyle={{ backgroundColor:"transparent", borderWidth:0, padding:0, margin:0}}
            checked={check1}
            checkedColor='white'
            size={26}
            onPress={() => setCheck1(!check1)}
                        />
  <Text style={{fontSize:16,color:'white'}}>Garder la session ouverte</Text>
</View></>}
</View>
</View>
        </LinearGradient>
        :
    <>
     
    <LinearGradient colors={['rgba(217, 228, 249, 1)','rgba(241, 246, 255, 1)']} start={[0.0, 1.4]} end={[1.4, 1.4]}  style={{flex : 1,flexDirection: 'row', borderTopLeftRadius:0,paddingHorizontal: 30, shadowColor: 'black', shadowOpacity: 0.4, shadowRadius: 7, shadowOffset: { height: 0, width:-7 }}}>
    
    <ScrollView>
    <View style={{  backgroundColor:"#FFFFFF", paddingHorizontal:15, borderRadius: 20,margin: 20, elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}>
    <View style={{ flexDirection:'row', backgroundColor:"#FFFFFF"}}>
    <View style={{flex:1}} >
    <Image style={{ width: '100%', resizeMode: 'contain',height :100 }} source={require("../assets/logo_HealthSquad_complet.png")} /> 
       </View>
    </View>
    </View>
    <View style={{  backgroundColor:"#FFFFFF", paddingHorizontal:15, borderRadius: 20,margin: 20, elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}>
    <View style={{ flexDirection:'row', backgroundColor:"#FFFFFF", alignContent:'center', justifyContent:'center'}}>
    <Text style={{ fontSize: 30, fontWeight:'bold', paddingVertical:10}}>{Nom}</Text>
    </View>
    </View>
    
   <View style={{ backgroundColor:"rgba(255, 255, 255, 0.4)", padding:20, margin:20, marginBottom:10, borderRadius: 20, elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}>
      <View style={{ flexDirection:'row',padding:0, margin:0, marginBottom:0, borderRadius: 20}}>
      
    
      <TouchableOpacity style={{ flexDirection:'row',
    paddingVertical: 8,
    paddingHorizontal:20,
    marginVertical: 0,
    borderTopLeftRadius:12,
    borderTopRightRadius:12,
    backgroundColor: step == 1 ? 'white' : 'rgba(217, 228, 249, 0.55)',
    alignItems:'center',
    justifyContent:'center'}} onPress={()=>{setStep(1)}}>
        <Text style={{ fontSize: 22,fontWeight: 'bold',color: 'rgba(57, 121, 193, 1)' }}>Listing</Text>
        
      </TouchableOpacity>
      <TouchableOpacity style={{ flexDirection:'row',
    paddingVertical: 8,
    paddingHorizontal:20,
    marginVertical: 0,
    marginHorizontal: 5,
    borderTopLeftRadius:12,
    borderTopRightRadius:12,
    backgroundColor: step == 2 ? 'white' : 'rgba(217, 228, 249, 0.55)',
    alignItems:'center',
    justifyContent:'center'}} onPress={()=>{setStep(2)}}>
        <Text style={{ fontSize: 22,fontWeight: 'bold',color:  'rgba(57, 121, 193, 1)' }}>Planning</Text>
        
      </TouchableOpacity>
     
      </View>
      <View style={{ backgroundColor:"#FFFFFF", padding:20, margin:0, marginBottom:0, borderTopRightRadius:20, borderBottomLeftRadius:20, borderBottomRightRadius:20}}>
      {(() => {
                   switch (step) {
                    case 0:   return <>
       </>;
                    case 1: return <>
      <DataTable.Header style={{ borderBottomColor:'#3F78E0', borderBottomWidth:2}}>
        <DataTable.Title style={{flex: 0.5}}><Text style={{fontWeight:'500',fontSize:16}}>Id.</Text></DataTable.Title>
        <DataTable.Title style={{flex: 0.5}}><Text style={{fontWeight:'500',fontSize:16}}>Sexe</Text></DataTable.Title>
        <DataTable.Title style={{flex: 0.5}}><Text style={{fontWeight:'500',fontSize:16}}>Âge</Text></DataTable.Title>
        <DataTable.Title style={{flex: 1.2}}><Text style={{fontWeight:'500',fontSize:16}}>Profession</Text></DataTable.Title>
        <DataTable.Title style={{flex: 1.2}}><Text style={{fontWeight:'500',fontSize:16}}>Rendez-vous</Text></DataTable.Title>
        <DataTable.Title style={{flex: 1}}><Text style={{fontWeight:'500',fontSize:16}}>Lieux du rendez-vous</Text></DataTable.Title>
        
      </DataTable.Header>
      <DataTable>
      { DATA.filter(word => word.session == 1).length == 0 ? undefined :
      <List.Accordion theme={{colors: {placeholder: '#3F78E0', primary: '#3F78E0', underlineColor: 'transparent', backgroundColor: '#3F78E0' } }}
     titleStyle={{textAlign:'center', fontWeight:'bold', marginLeft:0, fontSize:20}}
     style={{backgroundColor:'white', paddingTop:0, borderRadius:20, height:50,marginTop:10, borderColor:'#3F78E0', borderWidth:1}}
      title={'Formation (' + DATA.filter(word => word.session == 1).length + ')'}
      id={1}
      expanded={expanded1}
      onPress={() => setExpanded1(!expanded1)}
     >
      {DATA && DATA.filter(word => word.session == 1).map((item,index) => ( 
      <DataTable.Row key={index} >
        <DataTable.Cell style={{flex: 0.5}}><Text style={{fontWeight:'400',fontSize:16}}>{item.id}</Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 0.5}}><Text style={{fontWeight:'400',fontSize:16}}><MaterialCommunityIcons name={item.Sexe == 1 ? "gender-male": "gender-female"} color={item.Sexe == 1 ? "blue": "deeppink"} size={20} /></Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 0.5}}><Text style={{fontWeight:'400',fontSize:16}}>{ (new Date).getFullYear() - new Date(item.Age).getFullYear()}</Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 1.2}}><Text style={{fontWeight:'400',fontSize:16}}>{item.Nom_Projet}</Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 1.2}}><Text style={{fontWeight:'400',fontSize:16}}>{moment(item.date).format('ll')} à {item.hours}</Text></DataTable.Cell> 
        <DataTable.Cell style={{flex: 1}}>
       { item.Adresse_Rdv ? <Tooltip title={item.Adresse_Rdv}>
       <TouchableOpacity><Text style={{fontWeight:'400',fontSize:16}}>{result2(item.Lieux_Rdv)}</Text></TouchableOpacity>
  </Tooltip>:<Text style={{fontWeight:'400',fontSize:16}}>{result2(item.Lieux_Rdv)}</Text>}</DataTable.Cell>
       
      </DataTable.Row>
     
      ))} 
      </List.Accordion>}
      <List.Accordion theme={{colors: {placeholder: '#3F78E0', primary: '#3F78E0', underlineColor: 'transparent', backgroundColor: '#3F78E0' } }}
     titleStyle={{textAlign:'center', fontWeight:'bold', marginLeft:0, fontSize:20}}
     style={{backgroundColor:'white', paddingTop:0, borderRadius:20, height:50,marginTop:10, borderColor:'#3F78E0', borderWidth:1}}
      title={'Test Utilisateur (' + DATA.filter(word => word.session == 2).length + ')'}
      id={1}
      expanded={expanded2}
      onPress={() => setExpanded2(!expanded2)}
     >
      {DATA && DATA.filter(word => word.session == 2).map((item,index) => ( 
      <DataTable.Row key={index} >
        <DataTable.Cell style={{flex: 0.5}}><Text style={{fontWeight:'400',fontSize:16}}>{item.id}</Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 0.5}}><Text style={{fontWeight:'400',fontSize:16}}><MaterialCommunityIcons name={item.Sexe == 1 ? "gender-male": "gender-female"} color={item.Sexe == 1 ? "blue": "deeppink"} size={20} /></Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 0.5}}><Text style={{fontWeight:'400',fontSize:16}}>{ (new Date).getFullYear() - new Date(item.Age).getFullYear()}</Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 1.2}}><Text style={{fontWeight:'400',fontSize:16}}>{item.Nom_Projet}</Text></DataTable.Cell>
        <DataTable.Cell style={{flex: 1.2}}><Text style={{fontWeight:'400',fontSize:16}}>{moment(item.date).format('ll')} à {item.hours}</Text></DataTable.Cell> 
        <DataTable.Cell style={{flex: 1}}>   { item.Adresse_Rdv ? <Tooltip title={item.Adresse_Rdv}>
       <TouchableOpacity><Text style={{fontWeight:'400',fontSize:16}}>{result2(item.Lieux_Rdv)}</Text></TouchableOpacity>
  </Tooltip>:<Text style={{fontWeight:'400',fontSize:16}}>{result2(item.Lieux_Rdv)}</Text>}</DataTable.Cell>

        
      </DataTable.Row>
     
      ))} 
      </List.Accordion>
       </DataTable>
       </>;
       case 2: return <>
       <Calendar3 startingDate={new Date(itemP[0].Debut)} week={week(new Date())} DATA={DATA} />
       </>;
          default:      return undefined;
                  }
                })()}
      
      
        </View>
       
      </View> 
  </ScrollView>
  </LinearGradient>
    
  </>

  )
}

const styles = StyleSheet.create({
    
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    
  },
  modalView: {width: 450,
    margin: 30,
    backgroundColor: '#3F78E0',
    borderRadius: 5,
    padding: 0,
    elevation: 20 , 
    shadowColor: "#000000", 
    shadowOpacity: 0.5,
    shadowRadius: 5, 
    shadowOffset: { height: 5, width:5 }
   },
  textStyle: {
  color: 'white',
  fontWeight: "bold",
  textAlign: "right",
  fontSize: 20
   },
   TextInput: {
    height: 45,
    marginVertical:10,
    padding: 10,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 18
  },
  TextInput2: {
    height: 45,
    marginVertical:0,
    padding: 0,
    borderRadius:5,
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'grey',
    borderWidth:1,
    fontSize: 18
  },
   textStyle1: {
    color: '#00808B',
    fontWeight: "bold",
    textAlign: "right",
    marginHorizontal:10,
    fontSize: 24
     },
   modalText: {
  fontSize:16,
 color:'white',
 fontWeight:'bold'
   },
   modalTextbold: {
    fontWeight:'bold'
    },
    modalVText: {
      paddingBottom:20,
     // alignItems: "center"
       }

  });

export default Partage