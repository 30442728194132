import React, { useState, useEffect, useRef, Component } from 'react';
import {Button, Animated, StyleSheet, Text, Image, View, FlatList, useWindowDimensions, Platform, SafeAreaView, TouchableOpacity, Pressable } from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import 'moment/locale/fr';

const index = ({startingDate, week, DATA}) => {
    
    const numColumns = 7
    const DaysDays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
    const hours = ["8:00","8:15","8:30","8:45", "9:00","9:15","9:30","9:45", "10:00","10:15","10:30","10:45", "11:00","11:15","11:30","11:45", "12:00","12:15","12:30","12:45", "13:00","13:15","13:30","13:45", "14:00","14:15","14:30","14:45", "15:00","15:15","15:30","15:45", "16:00","16:15","16:30","16:45", "17:00","17:15","17:30","17:45", "18:00","18:15","18:30","18:45", "19:00","19:15","19:30","19:45"];
    const [NumJours, setNumJours] = useState([]);
    const Days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    const Months = ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Déc"];
    const [currentDate, setCurrentDate] = useState(startingDate)
    const [currentMonth, setCurrentMonth] = useState(startingDate.getMonth() + 1)
    const [currentYear, setCurrentYear] = useState(startingDate.getFullYear())
    const {height, width} = useWindowDimensions();

    const draggedELDateref = useRef();
    const draggedELHouref = useRef();
    const draggedELIref = useRef();
    
    console.log('DATAcalendar',DATA)
    console.log('currentMonth',currentMonth)
    console.log('currentYear',currentYear)
    console.log('startingDate',startingDate)

    //const MockEvents =  useState([data])
    
    // const MockEvents = [
    //   {id:1, date : '2023-10-31', title: "appointment1", hours: "11:00"},
    //   {id:2, date : '2023-11-20', title: "appointment2", hours: "08:00"},
    //   {id:3, date : '2023-11-15', title: "car wash1", hours: "12:00"},
    //   {id:4, date : '2023-11-15', title: "car wash2", hours: "13:00"},
    //   {id:5, date : '2023-11-05', title: "doctors", hours: "16:00"},
    //   {id:6, date : '2023-11-23', title: "sport", hours: "18:00"}
    // ];

    // const [events, setEvents] = useState(data)

    // const onAddEvent = (date) => {
    //   setEvents((prev) => [...prev, {"date" : date, "title" : "new" }])
    // }

    const onAddEvent1 = (date, hour, e) => {
      
  //     if(e.currentTarget === e.target){
  //     setEvents((prev) => [...prev, {id: events.length + 1, "date" : date.toISOString().slice(0, 10)  , hours: hour}])
  // console.log('max',events) } 
}

  // const onDragEvents = (updatedEvents) => {
  //   setEvents(updatedEvents)
  // }


  const onDragStart = (id) => {
    // console.log(id)
    // draggedELIref.current = id

  }

  const onDragEnter = (e, date, hour) => {
    console.log('max',events)
   e.preventDefault();
   draggedELDateref.current = date.toISOString().slice(0, 10)
   draggedELHouref.current = hour

 const updatedEvents = events.map((event) => {
if(event.id === draggedELIref.current){
  event.date = draggedELDateref.current
  event.hours = draggedELHouref.current
} return event
    })
    onDragEvents(updatedEvents)

  }

 


    const range = (end) => {
    const {result} = Array.from({length: end}).reduce(({result, current}) => ({result:[...result, current], current : current + 1}), { result:[], current: 1});
    return result
    }



    const getDaysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
        }

    const DaysInAMonth = getDaysInMonth(currentMonth, currentYear);

    const getSortedDays = (month, year) => {
        const dayIndex = new Date(year, month, 1).getDay();
        return [...Days.slice(dayIndex), ...Days.slice(0, dayIndex)]
    }

    const nextMonth = () => {
        if (currentMonth < 11) {
            setCurrentMonth(prev => prev + 1);
        } else {
            setCurrentMonth(0)
            setCurrentYear(prev => prev + 1);
        }
    }

    const prevMonth = () => {
        if (currentMonth > 0) {
            setCurrentMonth(prev => prev - 1);
        } else {
            setCurrentMonth(11)
            setCurrentYear(prev => prev - 1);
        }
    }


    const firstDaysofWeek = (currentdate) => {

      var determinedate = new Date(currentdate);
      // determinedate.setFullYear(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate());
      // var D = determinedate.getDay();
      // if(D == 0) D = 7;
      // determinedate.setDate(determinedate.getDate() + (4 - D));
      // var YN = determinedate.getFullYear();
      // var ZBDoCY = Math.floor((determinedate.getTime() - new Date(YN, 0, 1, -6)) / 86400000);
      // var WN = 1 + Math.floor(ZBDoCY / 7);
      // console.log(WN);
      
      const data = [];
      for (var i=0; i<7; i++) {
          data.push({"date" : new Date(determinedate.setDate(determinedate.getDate()-determinedate.getDay() + 1 + i)).toISOString().slice(0, 10)})
          console.log ("data",data)
      }
      return [...data]
      }

      const nextWeek = () => {
        var d = currentDate
        if (d.getFullYear() === currentDate.getFullYear()) {
            setCurrentDate(new Date(d.setDate(d.getDate() + 1 * 7)));
          } else {
            setCurrentYear(prev => prev + 1);
        }
      }

        const prevWeek = () => {
          var d = currentDate
              setCurrentDate(new Date(d.setDate(d.getDate() - 1 * 7)));
          }
       
      


    const semaine = (currentdate) => {  
var oneJan = new Date(currentdate.getFullYear(),0,1);
var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
var result1 = currentdate.setUTCDate(currentdate.getUTCDate()-currentdate.getUTCDay()+1)
console.log(`${result1}.`);
console.log(`aa The week number of the current date (${currentdate}) is ${result}.`);
return Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
 }

 
    
  return (
//     <View>
//     <View
//     style={{
//       justifyContent: 'center',
//       alignItems: 'center',
//       flexDirection:'row'
//     }}
//   >
//        <IconButton
//     icon="arrow-left"
//     iconColor='#ffffff'
//     containerColor='#517fa4'
//     size={20}
//     onPress={() => prevMonth()}
//   />
//   <Text>{Months[currentMonth]} {currentYear}</Text>
//    <IconButton
//     icon="arrow-right"
//     iconColor='#ffffff'
//     containerColor='#517fa4'
//     size={20}
//     onPress={() => nextMonth()}
//   />
//  </View> 
//     <View style={{flexDirection:'row'}}>
//     {getSortedDays(currentMonth, currentYear).map((item,index) => ( 
// <View key={index} style={{flex:1, backgroundColor:'#517fa4',padding:5,margin:1}}>
// <Text  style={{color:'white'}}>{item}</Text>
// </View>
// ))}
//     </View>

  

// <FlatGrid
//   itemDimension={(width - 100)/ 7}
//   data={range(DaysInAMonth)}
//   renderItem={({ item, index }) => ( 
//   <TouchableOpacity key={index} onPress={() => onAddEvent(new Date(currentYear, currentMonth, item))} style={{justifyContent: 'flex-start',borderRadius: 5,padding: 1,height: Platform.OS === 'ios' || Platform.OS === 'android' ? 50 : 150,backgroundColor: item == new Date().getDate() && currentYear == new Date().getFullYear() && currentMonth == new Date().getMonth() ? '#517fa4' : 'white',borderWidth:1}}>
//      <Text style={{color: item == new Date().getDate() && currentYear == new Date().getFullYear() && currentMonth == new Date().getMonth() ? 'white':'#517fa4'}}>{item}</Text>
//      {
//       events.map((ev, index) => ev.date.getDate() == item && currentYear == ev.date.getFullYear() && currentMonth == ev.date.getMonth() && 
//       <View key={index}>
//         <Text style={{fontSize : Platform.OS === 'ios' || Platform.OS === 'android' ? 10 : 16, backgroundColor:'darkblue', borderRadius: 3, padding: Platform.OS === 'ios' || Platform.OS === 'android' ? 1 : 5, color: 'white' }}>{ev.title}</Text>
//       </View>)
//      }
//     </TouchableOpacity>)}
// />

// </View>
<View style={{ backgroundColor:'white', padding:10, margin:20, borderRadius: 20, elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}>


<View
    style={{
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection:'row',
      backgroundColor:'white'
    }}
  >
       <MaterialCommunityIcons style={{padding:0}} name={"arrow-left-circle-outline"} color={'#3F78E0'} size={40} onPress={() => prevWeek()}/>
  <Text style={{fontSize: 20}}>semaine {semaine(currentDate)}</Text>
  <MaterialCommunityIcons style={{padding:0}} name={"arrow-right-circle-outline"} color={'#3F78E0'} size={40} onPress={() => nextWeek()}/>
 </View> 
 <View style={{flex: 22,flexDirection:'row', marginTop: 0}}>
 <View style={{flex: 1, marginTop: 30}}>
{hours.map((item,index) => ( 
<View key={index} style={{backgroundColor:'white', height:15, border: '1px solid white'}}>
{item.substr(item.length - 2, 2) == '00' && <Text>{item}</Text>}
</View>
))}
</View>
 {/* <View style={{marginTop: 50,width:15}}>
{hours.map((item,index) => ( 
<div key={index} style={{backgroundColor:'white',padding:0, height:50,border: '1px solid white', borderTop: '1px solid black'}}></div>
))}
</View> */}
<View style={{ flex: 21}}>
<View style={{flexDirection:'row',backgroundColor:'white', borderBottom: '1px solid #DCDCDC' }}>
{firstDaysofWeek(currentDate).map((item,index) => ( 
<View style={{ flex: 3}} key={index} >
<View style={{ backgroundColor:'white',padding:5, height:40, borderBottomWidth:3, alignItems:'center', justifyContent:'center'}}>
<Text  style={{color:'black', fontSize:16}}>{DaysDays[index]} {moment(item.date).format("DD/MM/YYYY")}</Text>
</View>
{hours.map((item1,index) => ( 
  <View key={index} style={{flexDirection:'row' ,height:15, borderTop: item1.substr(item1.length - 2, 2) == '00' ? '1px solid #DCDCDC' : undefined, borderLeft: '1px solid 	#DCDCDC',elevation:1}}  onDragEnter={(e)=>onDragEnter(e, new Date(item.date), item1)} onClick={(e) => onAddEvent1(new Date(item.date), item1, e)}>

{  DATA.map((ev, index) =>  ev.hours === item1 && new Date(item.date).getDate()  === new Date(ev.date).getDate() && new Date(item.date).getMonth()+1  === new Date(ev.date).getMonth()+1  &&

<Pressable  onHoverIn={() => {
    console.log('onHoverIn');
     
    }}
    onHoverOut={() => {
      console.log('onHoverOut');
    }} draggable onDragStart={()=> onDragStart(ev.id)} key={index} style={{flex:1, top:ev.hours * Number(ev.Duree_Rdv), height:Number(ev.Duree_Rdv), fontSize :  16, backgroundColor: '#3F78E0', borderRadius: 0, color: 'white', elevation: 20 , shadowColor: "#000000", shadowOpacity: 0.5,shadowRadius: 5, shadowOffset: { height: 5, width:5 } }}  >

<View style={{ alignItems:'flex-end', paddingRight:5}}><Text style={{color:'white', fontWeight:"bold"}}>{ev.session == 1 ? "F": "T"}</Text></View>
<View style={{alignItems:'center', justifyContent:'center' }}><Text style={{fontSize:24, color:'white'}}>{ev.id}</Text></View>


  </Pressable>

  )}   
</View>
 ))}

</View>
))}
</View>
 </View> 
 
</View>  
 
 
</View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});

export default index


{/* <span key={index} style={{backgroundColor:'white',padding:5, height:50, border: '1px solid red', borderColor:'grey', display: 'block'}}  onDragEnter={(e)=>onDragEnter(e, new Date(item.date), item1)} onClick={(e) => onAddEvent1(new Date(item.date), item1, e)}>

{  events.map((ev, index) =>  ev.hours === item1 && new Date(item.date).getDate()  === new Date(ev.date).getDate() && new Date(item.date).getMonth()+1  === new Date(ev.date).getMonth()+1  &&
<span draggable onDragStart={()=> onDragStart(ev.id)} key={index} style={{fontSize : Platform.OS === 'ios' || Platform.OS === 'android' ? 10 : 16, backgroundColor:'rgba(63, 120, 224, 0.2)', borderRadius: 3, padding: Platform.OS === 'ios' || Platform.OS === 'android' ? 1 : 5, color: 'black' }}  >
   {ev.title}
  </span>
)}     <Draggable renderColor='red' renderText={ev.title} onPressIn={onDragStart}
       onPressOut={()=>console.log('out press')} 
       onLongPress={()=>console.log('long press')}
       onShortPressRelease={()=>console.log('press drag')}/>
onPress={() => console.log(new Date(item.date), item1)}
 )

</span> */}